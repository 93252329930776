import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import styled from 'styled-components'

export const ImageIcon = styled.img<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || '40px'};
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 102px);

  @media (max-width: 499px) {
    min-height: calc(100vh - 87px);
  }
`

export const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
`

export const CloseButton = styled(BlueGradientButton)`
  width: 200px;
  margin-top: 30px;
`
