import * as ComponentReact from 'react'
import styled from 'styled-components'

import { elementStyle } from 'styles/element-style'
import { flexItemStyle } from 'styles/flex-item-style'

interface IProps extends IElement, IFlexItem, ComponentReact.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const StyledDiv = styled.div<IProps>`
  ${elementStyle}
  ${flexItemStyle}
`

export const Div = ComponentReact.forwardRef<HTMLDivElement, React.PropsWithChildren<IProps>>(
  ({ children, ...props }, ref) => (
    <StyledDiv ref={ref} {...props}>
      {children}
    </StyledDiv>
  ),
)
