/* eslint-disable @typescript-eslint/naming-convention */
import type { SubmitResultDisplayType } from '../submit-result/types'
import type {
  IAgentReviewData,
  AgentReviewFeatureName,
  ISubmitReviewPayload,
  IAgentBadgeInformationDisplayName,
} from 'store/agent-review/types'

import { AgentBadge } from '@rentspree/component-v2/dist/agent-badge'
import Button from '@rentspree/component-v2/dist/button'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import Panel from '@rentspree/component-v2/dist/panel'
import ButtonTag from '@rentspree/component-v3.components.atoms.button-tag'
import TextArea from '@rentspree/component-v3.components.atoms.text-area'
import StarRating from '@rentspree/component-v3.components.molecules.star-rating'
import { useEffect, useState } from 'react'

import { REVIEW_GUIDELINE_URL } from 'constant'
import { useDispatch, useSelector } from 'store'
import { useCustomResizeObserver } from 'store/@hooks/use-custom-resize-observer'
import { selectSubmittingStatus, actions } from 'store/agent-review'
import { COLOR } from 'styles/constants'
import tracker from 'tracker'
import { AGENT_REVIEW } from 'tracker/events'
import { buildEvent, sendEvent } from 'utils/event'

import { shortenName } from './helper'
import {
  AgentBadgeContainer,
  ButtonGroup,
  ComplimentOptions,
  ScrollContentWrapper,
  GuidelineLink,
  ReviewerName,
  ReviewSection,
  Container,
  SeparateLine,
  StarRatingWrapper,
  SubHeader,
  TextAreaWrapper,
  Title,
} from './styled'

type ReviewFormProps = {
  embedded?: boolean
  agentReviewData?: IAgentReviewData
  agentBadgeData?: IAgentBadgeInformationDisplayName
  token: string | null
  featureName: AgentReviewFeatureName
  setSubmitResult: (type: SubmitResultDisplayType) => void
  onCloseModal: () => void
}

type ComplimentOptionKey =
  | 'MARKET_EXPERTISE'
  | 'LOCAL_AREA_KNOWLEDGE'
  | 'COMMUNICATION_SKILLS'
  | 'ATTENTION_TO_DETAIL'

type SelectedComplimentOptions = Partial<Record<ComplimentOptionKey, 1>>

type StarRatingRange = 1 | 2 | 3 | 4 | 5

const COMPLIMENT_OPTION_VALUES: Record<ComplimentOptionKey, string> = {
  MARKET_EXPERTISE: 'Market Expertise',
  LOCAL_AREA_KNOWLEDGE: 'Local Area Knowledge',
  COMMUNICATION_SKILLS: 'Communication Skills',
  ATTENTION_TO_DETAIL: 'Attention To Detail',
}

const MIN_REVIEW_DETAIL_CHARACTERS = 50
const MAX_REVIEW_DETAIL_CHARACTERS = 2600

export const ReviewForm: React.FC<ReviewFormProps> = ({
  embedded,
  agentReviewData,
  agentBadgeData,
  token,
  featureName,
  setSubmitResult,
  onCloseModal,
}) => {
  const [starRating, setStarRating] = useState<StarRatingRange>(5)
  const [reviewDetail, setReviewDetail] = useState<string>('')
  const [reviewDetailErrorMessage, setReviewDetailErrorMessage] = useState<string>()
  const [selectedComplimentOptions, setSelectedComplimentOptions] =
    useState<SelectedComplimentOptions>({})
  const [submitType, setSubmitType] = useState<'submit' | 'reject' | undefined>()
  const [isShowErrorAlert, setIsShowErrorAlert] = useState<boolean>(false)
  const [hasTouched, setHasTouched] = useState<boolean>(false)

  const contentResizeObserver = useCustomResizeObserver<HTMLDivElement>()

  const dispatch = useDispatch()
  const submittingStatus = useSelector<RequestStatus>(selectSubmittingStatus)

  const isSubmitting = submittingStatus === 'pending'

  const toggleComplimentOption = (option: ComplimentOptionKey) => {
    setSelectedComplimentOptions((options: SelectedComplimentOptions) => {
      const tmpOptions = { ...options }

      if (tmpOptions[option]) delete tmpOptions[option]
      else tmpOptions[option] = 1

      return tmpOptions
    })
  }

  const handleStarRatingChange = (percentage: number) => {
    if (isSubmitting) return
    setStarRating((percentage / 20) as StarRatingRange)
  }

  const validateReviewDetailError = (value: string, shouldSetErrorMessage: boolean) => {
    if (value.length < MIN_REVIEW_DETAIL_CHARACTERS && value.length > 0) {
      if (shouldSetErrorMessage) {
        setReviewDetailErrorMessage(
          `Your review should be more than ${MIN_REVIEW_DETAIL_CHARACTERS.toLocaleString()} characters.`,
        )
      }
    } else if (value.length > MAX_REVIEW_DETAIL_CHARACTERS) {
      if (shouldSetErrorMessage) {
        setReviewDetailErrorMessage(
          `Your review should be less than ${MAX_REVIEW_DETAIL_CHARACTERS.toLocaleString()} characters.`,
        )
      }
    } else {
      setReviewDetailErrorMessage(undefined)
    }
  }

  const handleReviewDetailChange = (value: string) => {
    setReviewDetail(value)
    validateReviewDetailError(value, hasTouched)
  }

  const handleReviewSubmission = () => {
    if (starRating > 3 && reviewDetailErrorMessage) return
    const compliments = (Object.keys(selectedComplimentOptions) as ComplimentOptionKey[]).map(
      (option: ComplimentOptionKey) => COMPLIMENT_OPTION_VALUES[option],
    )
    const submitReviewPayload: ISubmitReviewPayload = {
      token,
      data: {
        type: 'submit',
        star: starRating,
        ...(starRating > 3 ? { compliments, recommendation: reviewDetail } : {}),
      },
    }
    setSubmitType('submit')
    // eslint-disable-next-line no-void
    void dispatch(actions.submitAgentReview(submitReviewPayload))
  }

  const handleNeverWorkWithThisAgentClick = () => {
    const submitReviewPayload: ISubmitReviewPayload = {
      token,
      data: {
        type: 'reject',
      },
    }
    setSubmitType('reject')
    // eslint-disable-next-line no-void
    void dispatch(actions.submitAgentReview(submitReviewPayload))
  }

  useEffect(() => {
    if (embedded) {
      contentResizeObserver.registerCallbackOnce((width, height) => {
        sendEvent(
          buildEvent({
            domain: 'rentspree_agent_profile_public_page',
            context: 'agent_review_page',
            eventName: 'content_resized',
            payload: {
              width,
              height,
            },
          }),
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embedded])

  useEffect(() => {
    if (submittingStatus === 'rejected') {
      setIsShowErrorAlert(true)
      dispatch(actions.setSubmittingStatus('initial'))
    } else if (submittingStatus === 'fulfilled' && submitType === 'submit') {
      setSubmitResult('thankYou')
      let isRecommendation = 'no'
      let complimentValues: string[] = []
      if (starRating > 4) {
        isRecommendation = reviewDetail.length > 1 ? 'yes' : 'no'
        complimentValues = (Object.keys(selectedComplimentOptions) as ComplimentOptionKey[]).map(
          (option: ComplimentOptionKey) => COMPLIMENT_OPTION_VALUES[option],
        )
      }
      tracker?.trackEvent(AGENT_REVIEW.EVENT.CLICK_SUBMIT_REVIEW, {
        [AGENT_REVIEW.PROPERTY.STAR_RATING]: starRating,
        [AGENT_REVIEW.PROPERTY.COMPLIMENTS_NAME]: complimentValues,
        [AGENT_REVIEW.PROPERTY.ADD_RECOMMENDATION]: isRecommendation,
        applicants: {
          rental_id: agentReviewData?.rentalSubmissionId,
          property_id: agentReviewData?.propertyId,
        },
        feature_name: featureName,
      })
    } else if (submittingStatus === 'fulfilled' && submitType === 'reject') {
      tracker?.trackEvent(AGENT_REVIEW.EVENT.CLICK_I_DID_NOT_WORK_WITH_THIS_AGENT, {
        applicants: {
          rental_id: agentReviewData?.rentalSubmissionId,
          property_id: agentReviewData?.propertyId,
        },
        feature_name: featureName,
      })
      setSubmitResult('gotIt')
      if (embedded) {
        onCloseModal()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittingStatus, submitType])

  return (
    <Container
      data-testid="agent-review-form"
      embedded={embedded}
      ref={contentResizeObserver.elementRef}
    >
      <ScrollContentWrapper embedded={embedded}>
        <Title>Rate Your Agent</Title>
        <GuidelineLink href={REVIEW_GUIDELINE_URL} target="_blank" rel="noreferrer">
          Read the review guidelines
        </GuidelineLink>
        {isShowErrorAlert && (
          <Panel
            data-testid="error-alert-panel"
            margin="0 0 10px 0"
            status="error"
            titleMessage="We’ve encountered an error saving your info. Please try again."
            onClose={() => {
              setIsShowErrorAlert(false)
            }}
          />
        )}
        <AgentBadgeContainer id="agent-bagde-container">
          <AgentBadge
            id="withAgentProfile"
            imageSrc={agentBadgeData?.image}
            displayName={agentBadgeData?.displayName}
            information={agentBadgeData?.information}
            isVerified={agentBadgeData?.isVerified}
            width="60px"
            height="60px"
            mWidth="60px"
            mHeight="60px"
            titleSize="20px"
            subTitleSize="14px"
            margin="10px 0 0 0"
          />
        </AgentBadgeContainer>
        <SeparateLine />
        <ReviewSection>
          <SubHeader>Rate your overall experience</SubHeader>
          <StarRatingWrapper data-testid="star-rating-wrapper">
            <StarRating
              size="lg"
              gap="17.49px"
              color={COLOR.DEEP_BLUE_DARK}
              percentage={starRating * 20}
              onChange={handleStarRatingChange}
            />
          </StarRatingWrapper>
          {starRating > 3 && (
            <>
              <SubHeader>Select quick compliments</SubHeader>
              <ComplimentOptions data-testid="complement-options">
                {(Object.keys(COMPLIMENT_OPTION_VALUES) as ComplimentOptionKey[]).map(
                  (option: ComplimentOptionKey) => (
                    <ButtonTag
                      key={option}
                      text={COMPLIMENT_OPTION_VALUES[option]}
                      active={selectedComplimentOptions[option] === 1}
                      onClick={() => toggleComplimentOption(option)}
                      disabled={isSubmitting}
                    />
                  ),
                )}
              </ComplimentOptions>
              <SubHeader>Share details of your experience</SubHeader>
              <TextAreaWrapper data-testid="recommendation-area-wrapper">
                <TextArea
                  expectedCharacterLength={MAX_REVIEW_DETAIL_CHARACTERS}
                  errorMessage={reviewDetailErrorMessage}
                  value={reviewDetail}
                  onTextChange={handleReviewDetailChange}
                  onBlur={() => {
                    setHasTouched(true)
                    validateReviewDetailError(reviewDetail, true)
                  }}
                  disabled={isSubmitting}
                />
              </TextAreaWrapper>
              <SubHeader>Leaving review as:</SubHeader>
              <ReviewerName>
                {agentReviewData?.reviewer?.name && shortenName(agentReviewData?.reviewer?.name)}
              </ReviewerName>
            </>
          )}
        </ReviewSection>
        <ButtonGroup embedded={embedded}>
          <BlueGradientButton
            id="submit-review-button"
            data-testid="submit-review-button"
            large
            google
            text="Submit Review"
            mWidth="100%"
            height="40px"
            width="285px"
            margin="0 auto 15px auto"
            size="16px"
            loading={isSubmitting && submitType === 'submit'}
            disabled={isSubmitting && submitType === 'reject'}
            onClick={handleReviewSubmission}
          />
          <Button
            id="i-did-not-work-with-this-agent-button"
            data-testid="i-did-not-work-with-this-agent-button"
            google
            large
            text="I did not work with this agent"
            mWidth="100%"
            height="40px"
            width="285px"
            margin="auto"
            size="16px"
            loading={isSubmitting && submitType === 'reject'}
            disabled={isSubmitting && submitType === 'submit'}
            onClick={handleNeverWorkWithThisAgentClick}
          />
        </ButtonGroup>
      </ScrollContentWrapper>
    </Container>
  )
}
