import PropTypes from 'prop-types'

import { StyledButton, GradientBg, ButtonIcon } from '../styled-button'

import { FinishedButton } from './finished-button'
import { ProcessingButton } from './processing-button'

export const GradientButton: React.FC<IGradientButton> = ({
  text,
  children,
  loadingText,
  loading,
  loaded,
  onClick,
  hasIcon,
  gradient,
  disabled,
  badge,
  ...props
}) => {
  if (loading) {
    return (
      <ProcessingButton gradientHovered={gradient} {...props}>
        {loadingText}
      </ProcessingButton>
    )
  }

  if (loaded) {
    return <FinishedButton {...props} />
  }

  return (
    <StyledButton as="button" transparent {...props} onClick={onClick} disabled={disabled}>
      <GradientBg className="background" grayDisabled={disabled} gradient={gradient} />
      {text && text}
      {children && children}
      {hasIcon && <ButtonIcon ml className="fal fa-angle-right" />}
    </StyledButton>
  )
}

GradientButton.propTypes = {
  /** Button ID */
  id: PropTypes.string.isRequired,

  /** button text */
  text: PropTypes.string,

  /** type of button */
  type: PropTypes.oneOf(['button', 'submit']),

  /** indicator for button loading state */
  loading: PropTypes.bool,

  /** text show when loading state */
  loadingText: PropTypes.string,

  /** indicator for button success state */
  loaded: PropTypes.bool,

  /** indicator for button disabled state */
  disabled: PropTypes.bool,

  /** indicator for button size */
  btnSize: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),

  /** indicator for showing shadow of the button */
  shadow: PropTypes.bool,

  /** indicator for showing arrow icon on the button */
  hasIcon: PropTypes.bool,

  /** style for semi bold text in the component */
  semiBold: PropTypes.bool,

  /** style for displaying text in black color */
  blackText: PropTypes.bool,

  /** style for displaying text in blue color */
  blueText: PropTypes.bool,

  /** component height */
  height: PropTypes.string,

  /** component width */
  width: PropTypes.string,

  /** margin style for component */
  margin: PropTypes.string,

  /** padding style for component */
  padding: PropTypes.string,

  /** function for handle click action */
  onClick: PropTypes.func,

  /** mobile width */
  mWidth: PropTypes.string,

  /** mobile height */
  mHeight: PropTypes.string,

  /** gradient color */
  gradient: PropTypes.oneOf(['blue', 'red', 'yellow', 'secondary']),

  /** children */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  /** badge */
  badge: PropTypes.string,

  /** Radius */
  borderRadius: PropTypes.string,
}

GradientButton.defaultProps = {
  loading: false,
  loaded: false,
  btnSize: 'md',
  shadow: false,
  type: 'button',
  gradient: 'blue',
  text: undefined,
  loadingText: undefined,
  disabled: false,
  hasIcon: false,
  semiBold: false,
  blackText: false,
  blueText: false,
  height: undefined,
  width: undefined,
  margin: undefined,
  padding: undefined,
  onClick: () => ({}),
  mWidth: undefined,
  mHeight: undefined,
  children: undefined,
  badge: undefined,
  borderRadius: undefined,
}

export default GradientButton
