import isEmpty from 'lodash/isEmpty'
import { useMedia } from 'react-use'

import { ImageIcon, Hr, Text } from 'components'
import { useSelector } from 'store'
import { selectAgentContactInfo, selectAgentProfileFullName } from 'store/agent-profile'
import { selectUserStateStatus } from 'store/users'
import tracker, { events } from 'tracker'
import { loadAccessToken } from 'utils/access-token'
import { interpolateMessage } from 'utils/interpolate-message'

import { CallAgentCTA } from './buttons/call-cta'
import { ContactAgentCTA } from './buttons/contact-cta'
import { PLACEHOLDER as placeHolderData } from './email-modal/constants'
import { ContactAgentEmailModal } from './email-modal/contact-agent-email-modal'
import { ContactAgentMessageBox } from './message-box'
import { StickyButtonContainer } from './message-box/style'

export const ContactAgent: React.FC<{ isPreview?: boolean }> = ({ isPreview }) => {
  const fullName = useSelector(selectAgentProfileFullName)
  const { phone } = useSelector(selectAgentContactInfo)
  const userStateStatus = useSelector(selectUserStateStatus)
  const isMobile = useMedia('(max-width: 991px)')

  const isUserHaveCookie = !isEmpty(loadAccessToken().accessToken)
  const isUserLogIn = isUserHaveCookie && userStateStatus === 'fulfilled'
  const messageTemplate = placeHolderData.agentProfile.MESSAGE
  return !isPreview && isUserLogIn ? (
    <ContactAgentMessageBox agentFullName={fullName} agentPhone={phone} />
  ) : (
    <ContactAgentEmailModal
      agentFullName={fullName}
      defaultMessage={interpolateMessage(messageTemplate, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        agent_name: fullName,
      })}
    >
      {(onClick, hideMobile) => (
        <>
          <Hr $mt="32" $hideMobile />
          <StickyButtonContainer>
            <CallAgentCTA
              id="call-agent-button"
              $hideMobile={hideMobile}
              onClick={() => {
                if (phone) {
                  tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_CALL_TO_AGENT, {
                    state: isUserLogIn ? 'loggedin' : 'anonymous',
                  })
                  window.location.href = `tel:${phone}`
                }
              }}
              disabled={!phone}
            >
              <ImageIcon
                src={phone ? '../profile/phone-ring.svg' : '../profile/phone-ring-disabled.svg'}
              />
              <Text $size="14px" $bold>
                Call
              </Text>
            </CallAgentCTA>
            <ContactAgentCTA
              id="contact-agent-via-email-button"
              onClick={onClick}
              $hideMobile={hideMobile}
            >
              {isMobile && <ImageIcon src="../profile/message.svg" />}
              {isMobile ? 'Message' : `Contact ${fullName}`}
            </ContactAgentCTA>
          </StickyButtonContainer>
        </>
      )}
    </ContactAgentEmailModal>
  )
}
