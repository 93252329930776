import type { IProcessingOptionsInterface } from '../interfaces'
import type { SizeOptionsType } from '../types'

import { parseArguments } from '../helper/helper'

export class SizeProcessingOption implements IProcessingOptionsInterface {
  width?: number

  height?: number

  enlarge?: boolean

  extend?: boolean

  readonly orderOptions = ['width', 'height', 'enlarge', 'extend']

  readonly defaultValue = {
    width: undefined,
    height: undefined,
    enlarge: false,
    extend: false,
  }

  constructor({ width, height, enlarge, extend }: SizeOptionsType) {
    this.width = width
    this.height = height
    this.enlarge = enlarge
    this.extend = extend
  }

  update({ width, height, enlarge, extend }: SizeOptionsType): this {
    this.width = width
    this.height = height
    this.enlarge = enlarge
    this.extend = extend
    return this
  }

  parseOptions(): string {
    const argsOrder: unknown[] = this.orderOptions.map((key: string): unknown => this[key])
    const defaultValue: unknown[] = this.orderOptions.map(
      (key: string): unknown => this.defaultValue[key],
    )
    const args = parseArguments(argsOrder, defaultValue)

    return `s:${args}`
  }
}
