import { Icon as IconV2 } from '@rentspree/ssr-component-v2'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLOR } from 'styles/constants'

type IconSize = 'sm' | 'md' | 'lg'

interface IconPropsInterface {
  name: string
  size?: IconSize
  $hideDesktop?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  disabled?: boolean
  color?: string
  $ml?: string
  $mt?: string
  className?: string
}

// need this line to prevent eslint error on styled section
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactIcon: React.FC<IconPropsInterface> = (props: any) => <IconV2 {...props} />

const StyledIcon = styled(ReactIcon)`
  ${({ $hideDesktop }) =>
    $hideDesktop && 'display: none; @media(max-width: 991px) {display: inline-block;}'}
  ${({ onClick, disabled }) => !disabled && !!onClick && 'cursor: pointer;'}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
`

export const Icon: React.FC<IconPropsInterface> = (props: IconPropsInterface) => (
  <StyledIcon {...props} />
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  $hideDesktop: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  $ml: PropTypes.string,
  $mt: PropTypes.string,
  className: PropTypes.string,
}

Icon.defaultProps = {
  size: 'sm',
  $hideDesktop: false,
  onClick: undefined,
  disabled: undefined,
  color: COLOR.BLUE_LV5,
  $ml: undefined,
  $mt: undefined,
  className: undefined,
}

export default Icon
