import styled from 'styled-components'

import { Alert } from 'components/common/organisms/alert'
import { COLOR } from 'styles/constants'
import { elementStyle } from 'styles/element-style'

export const ContactAgentEmailModalContent: React.FC<
  React.PropsWithChildren<IElement>
> = styled.div`
  max-width: 542px;
  padding: 40px 56px;

  & > span:first-child:before {
    content: '';
    float: right;
    width: 7px;
    height: 32px;
  }

  color: ${COLOR.GREY_LV1};

  @media (max-width: 991px) {
    padding: 24px 28px;
  }

  ${elementStyle}
`

export const ContactAgentInformationAlert = styled(Alert)`
  & > img {
    filter: invert(17%) sepia(40%) saturate(7024%) hue-rotate(202deg) brightness(93%) contrast(112%);
  }
`
