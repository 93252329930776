import ReviewItem from '@rentspree/component-v3.components.organisms.review-item'

import { H3, Hr } from 'components'
import { useSelector } from 'store'
import { selectAgentReview } from 'store/agent-review-page'

import { ReviewWrapper } from './styles'

export const ReviewSection: React.FC = () => {
  const review = useSelector(selectAgentReview)
  return (
    <>
      <H3>Review</H3>
      <ReviewWrapper>
        <ReviewItem
          firstName={review.reviewer?.firstName}
          lastName={review.reviewer?.lastName}
          reviewDate={review.reviewDate}
          renterRole={review.reviewer?.role}
          star={review.star}
          review={review.recommendation}
          compliments={review.compliments}
          isUIKitV4
        />
        <Hr $mt="16" />
      </ReviewWrapper>
    </>
  )
}
