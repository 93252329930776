import styled from 'styled-components'

import { COLOR } from 'styles/constants'

export const PaginationWrapper = styled.div`
  justify-content: flex-end;
  margin-top: 48px;
`

export const PagerBtn = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${COLOR.GREY_LV4};
  border-radius: 4px;
  background: ${COLOR.WHITE};
  color: ${COLOR.GREY_LV1};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  margin: 0 4px;
  padding: 0px;
  :hover {
    color: ${COLOR.BLUE_LV4};
  }
  :disabled {
    background: ${COLOR.GREY_LV6};
    border: 1px solid ${COLOR.GREY_LV4};
  }
  &.current {
    transition: background ease 0.2s;
    background-color: ${COLOR.BLUE_LV2};
    cursor: default;
    color: ${COLOR.WHITE};
    border: 1px solid ${COLOR.BLUE_LV2};
  }
  .icon {
    display: block;
    width: 25px;
    height: 20px;
    background-size: cover;
    padding: 0px;
  }
  .icon-left-arrow {
    margin-left: 10px;
    background-image: url(/profile/icon/left-chevron-black.svg);
  }
  .icon-right-arrow {
    margin-left: 2px;
    background-image: url(/profile/icon/right-chevron-black.svg);
  }
  .icon-left-arrow:hover,
  .icon-right-arrow:hover {
    filter: ${COLOR.SVG_BLUE_LV4};
  }
  .icon-left-arrow.disabled,
  .icon-right-arrow.disabled {
    filter: ${COLOR.SVG_GREY_LV3};
  }
`

export const Pager = styled.div`
  height: 100%;
  display: inline-flex;
`
