import { Box, Grid } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import { isEmpty } from 'lodash'

import { ShowMoreText } from 'components'
import { useSelector } from 'store'
import { selectAgentProfileExperience } from 'store/agent-profile'

const TITLE = 'Experience & certifications'

interface IExperience {
  onShowMoreTextReady?: (v: number) => void
}

export const Experience: React.FC<IExperience> = ({ onShowMoreTextReady }) => {
  const { agentLicenses, brokerageLicense, certificates, year } = useSelector(
    selectAgentProfileExperience,
  )

  const hasYears = !!year
  const hasAgentLicenses = !isEmpty(agentLicenses?.[0]?.number)
  const hasBrokerageLicenses = !isEmpty(brokerageLicense)
  const hasCertificates = !isEmpty(certificates)
  if (!hasYears && !hasAgentLicenses && !hasBrokerageLicenses && !hasCertificates) return null
  return (
    <Box marginBottom="24px" data-testid="experience-container">
      <Card variant="outlined">
        <CardElement>
          <Typography variant="title-medium" fontWeight="bold" component="div">
            {TITLE}
          </Typography>
        </CardElement>
        <CardElement>
          <Grid container>
            <Grid item lg={4} md={4} sm={12}>
              {hasYears && (
                <Box>
                  <Typography variant="title" marginBottom="4px" component="div">
                    Years of experience
                  </Typography>
                  <Typography variant="body" marginBottom="4px">
                    {year}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              {hasAgentLicenses && (
                <Box>
                  <Typography variant="title" marginBottom="4px" component="div">
                    Real estate license
                  </Typography>
                  <Typography variant="body" marginBottom="4px">
                    {agentLicenses?.[0]?.number}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              {hasBrokerageLicenses && (
                <Box>
                  <Typography variant="title" marginBottom="4px" component="div">
                    Brokerage license
                  </Typography>
                  <Typography variant="body" marginBottom="4px">
                    {brokerageLicense}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Box>
            {hasCertificates && (
              <Grid item xs={12} marginTop="8px">
                <Typography variant="title" marginBottom="4px" component="div">
                  Real Estate Certifications
                </Typography>
                <ShowMoreText line={4} lineOffset={2} $mt="4" onReady={onShowMoreTextReady}>
                  <Box>
                    <Typography variant="body" component="ul">
                      {certificates?.map(certificate => (
                        <li key={certificate.number}>
                          <Typography
                            variant="body"
                            fontWeight="bold"
                            marginBottom="4px"
                            marginRight="4px"
                          >
                            {certificate.name}
                          </Typography>
                          <Typography variant="body">{certificate.number}</Typography>
                        </li>
                      ))}
                    </Typography>
                  </Box>
                </ShowMoreText>
              </Grid>
            )}
          </Box>
        </CardElement>
      </Card>
    </Box>
  )
}
