import styled from 'styled-components'

import { Div } from 'components/common/atoms'
import { elementStyle } from 'styles/element-style'

export const ContactInformationWrapper = styled(Div)`
  display: flex;
  column-gap: 20px;
  margin-bottom: 16px;
`
export const SocialMedias = styled(Div)`
  display: flex;
  column-gap: 24px;
  align-items: center;
  @media only screen and (max-width: 991px) {
    justify-content: center;
  }
`

export const TradeMarkWrapper = styled(Div)`
  ${elementStyle}
  @media only screen and (max-width: 991px) {
    text-align: center;
  }
`
