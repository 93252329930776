import type { InputElementInterface, NumberFormatElement } from './types'

import NumberFormat from 'react-number-format'
import styled from 'styled-components'

import { baseInputCSS } from './base-input-css'

export const NumberFormatInput = styled<NumberFormatElement & InputElementInterface>(NumberFormat)`
  ${baseInputCSS}
`
