import { Container, Title, Description, Wrapper, StyledButton, ImageIcon } from './styled'

export const ErrorResult: React.FC = () => (
  <Container data-testid="agent-review-error-result">
    <Wrapper>
      <ImageIcon src="/review/sweetalert-error.svg" />
      <Title>Error loading page</Title>
      <Description>
        Sorry, we’re having some trouble loading the page. Please try refresh the page.
      </Description>
      <StyledButton
        data-testid="review-reload-button"
        id="review-reload-button"
        margin="auto"
        google
        small
        text="Try Again"
        onClick={() => window.location.reload()}
      />
    </Wrapper>
  </Container>
)
