import styled from 'styled-components'

import { elementStyle } from 'styles/element-style'

type Props = IElement

export const Ul: React.FC<React.PropsWithChildren<Props>> = styled.ul<Props>`
  margin: 0;
  padding-left: 20px;
  ${elementStyle};
`
