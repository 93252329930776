import type { FlattenSimpleInterpolation } from 'styled-components'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Span } from 'components/common/atoms/typography/span'
import { BUTTON_HEIGHT, FLEX_CENTER, BUTTON_SHADOW, HOVER_SHADOW, COLOR } from 'styles/constants'
import { DESKTOP_BUTTON, MOBILE_BUTTON } from 'styles/css'

import * as color from './color'

const largeBtn = css`
  height: 48px;
  ${DESKTOP_BUTTON}
  padding: 10px 24px;
  border-radius: 40px;
`
const mediumBtn = css`
  height: 40px;
  ${DESKTOP_BUTTON}
  padding: 6px 24px;
  border-radius: 20px;
`

const smallBtn = css`
  height: 32px;
  ${MOBILE_BUTTON}
  padding: 4px 16px;
  border-radius: 16px;
`

const extraSmallBtn = css`
  height: 24px;
  ${MOBILE_BUTTON}
  padding: 0px 8px;
  border-radius: 12px;
`

const BUTTON_CSS_BY_SIZE = {
  lg: largeBtn,
  md: mediumBtn,
  sm: smallBtn,
  xs: extraSmallBtn,
}

const gradientHoveredColors: Record<GradientColorsChoices, FlattenSimpleInterpolation> = {
  blue: color.blueGradientHovered,
  yellow: color.yellowGradientHovered,
  red: color.redGradientHovered,
  secondary: color.secondaryGradientHovered,
}

export const StyledButton: React.FC<IStyledButton> = styled(Span)<IStyledButton>`
  ${FLEX_CENTER};
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: ${BUTTON_HEIGHT};
  cursor: pointer;
  font-family: Poppins, sans-serif;
  ${props => (props.btnSize ? BUTTON_CSS_BY_SIZE[props.btnSize] : mediumBtn)};
  ${props => props.borderRadius && `border-radius: ${props.borderRadius}`}
  ${props => props.google && color.googleBtn};
  ${props => props.facebook && color.facebookBtn};
  ${props => props.blueBtn && color.blueBtn}
  ${props => props.transparent && color.transparentBtn};
  ${props => props.fontSize && `font-size: ${props.fontSize}`}
  ${props => props.gradientHovered && gradientHoveredColors[props.gradientHovered]}
  ${props => props.greenGradient && color.greenGradientBtn};
  ${props => props.blackText && color.blackTextBtn};
  ${props => props.blueText && color.blueText};
  ${props => props.shadow && `box-shadow: ${BUTTON_SHADOW}`};
  ${props => props.shadowLevel2 && `box-shadow: ${HOVER_SHADOW}`};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.minWidth && `min-width: ${props.minWidth}px`};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${props => props.height && `height: ${props.height}`};
  ${props => props.width && `width: ${props.width}`};
  ${props => props.fontWeight && `font-weight: ${props.fontWeight}`};
  ${props =>
    props.mWidth &&
    `@media (max-width: 991px) {
      width: ${props.mWidth};
    }`}
  ${props =>
    props.mHeight &&
    `@media (max-width: 991px) {
      height: ${props.mHeight};
    }`}
  ${props =>
    props.mMargin &&
    `@media (max-width: 991px) {
      margin: ${props.mMargin};
    }`}
  ${props =>
    props.mBtnSize &&
    css`
      @media (max-width: 991px) {
        ${BUTTON_CSS_BY_SIZE[props.mBtnSize] || mediumBtn}
      }
    `}
  &:hover > .background {
    transition: all, 0.5s;
    transform: translateX(50%);
  }
  ${props =>
    props.disabled &&
    `
      cursor: not-allowed;
      box-shadow: 0 0 0 0;
      background-color: ${COLOR.GREY_LV4};
      color: ${COLOR.GREY_LV3};
    `};
  ${props => props.hidden && 'display: none'};
`

StyledButton.propTypes = {
  id: PropTypes.string.isRequired,
}

export default StyledButton
