import type { EmailModalFooterProps } from './types'

import { ButtonLink, RichText } from 'components'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constant'

import { EMAIL_AGREEMENT_TERMS_AND_PRIVACY, EMAIL_AGREEMENT } from './constants'

export const EmailModalFooter: React.FC<EmailModalFooterProps> = ({ hasTerm }) => (
  <RichText $textStyle="caption" $mt="10" $block>
    {hasTerm ? (
      EMAIL_AGREEMENT
    ) : (
      <>
        {EMAIL_AGREEMENT_TERMS_AND_PRIVACY}
        <ButtonLink
          $textStyle="caption"
          $regular
          text="Terms of Use"
          onClick={() => window.open(TERMS_OF_USE_URL, '_blank')}
        />{' '}
        and{' '}
        <ButtonLink
          $textStyle="caption"
          $regular
          text="Privacy Policy"
          onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
        />
        .
      </>
    )}
  </RichText>
)
