import styled from 'styled-components'

export const ButtonIcon: React.FC<IButtonIcon> = styled.i<IButtonIcon>`
  pointer-events: none;
  ${props => `font-size: ${props.size ? props.size : '18px'}`};
  ${props => `line-height: ${props.lineHeight ? props.lineHeight : '40px'}`};
  ${props => props.ml && 'margin-left: 5px'};
  ${props => props.mr && 'margin-right: 5px'};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.width && `width: ${props.width}px`};
  ${props => props.color && `color: ${props.color}`};
`

export default ButtonIcon
