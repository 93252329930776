import styled from 'styled-components'

import { COLOR } from 'styles/constants'
import { textStyle } from 'styles/text-style'

interface ILink extends ISpan {
  text: string | React.ReactNode
  $color?: string
  className?: string
}

const StyledLink = styled.span<ISpan>`
  cursor: pointer;

  :hover {
    color: ${COLOR.BLUE_LV5};
  }

  ${textStyle}
`

export const ButtonLink: React.FC<ILink> = ({ text, $color = COLOR.BLUE_LV2, ...style }) => (
  <StyledLink $textStyle="button" $color={$color} {...style}>
    {text}
  </StyledLink>
)
