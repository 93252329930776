import { RentSpeeSweetAlertOption } from 'components'

export const TERMS_AND_PRIVACY =
  'I agree to receive emails from or on behalf of RentSpree, its real estate agents, property managers, and landlords related to properties that I may be interested in. '

export const GO_TO_MESSAGES_MODAL_OPTIONS = {
  ...RentSpeeSweetAlertOption,
  customClass: {
    cancelButton: 'rsp-swal-btn rsp-swal-cancel-btn',
    confirmButton: 'rsp-swal-btn rsp-swal-confirm-btn go-to-messages-btn',
    denyButton: 'rsp-swal-btn rsp-swal-deny-btn',
    container: 'rsp-swal-container go-to-messages-container',
  },
}
