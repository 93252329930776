import type { InputElementInterface } from './types'

import { css } from 'styled-components'

import { COLOR } from 'styles/constants'
import { DESKTOP_BODY_SMALL } from 'styles/css'
import { elementStyle } from 'styles/element-style'

export const baseInputCSS = css<InputElementInterface>`
  border-radius: ${({ $borderRadius }) => $borderRadius || '4px'};
  border: 1px solid ${({ $error }) => ($error ? COLOR.RED_LV2 : COLOR.GREY_LV4)};
  ${({ disabled }) => disabled && `background-color: ${COLOR.GREY_LV6}`};
  ${DESKTOP_BODY_SMALL}

  width: 320px;
  height: 40px;
  padding: 8px;

  :focus {
    border-color: ${COLOR.BLUE_LV4};
    outline: none;
  }

  ::placeholder {
    ${DESKTOP_BODY_SMALL}
    color: ${COLOR.GREY_LV3}
  }

  ${elementStyle}
`
