import styled from 'styled-components'

import { elementStyle } from 'styles/element-style'

interface IImg extends IElement {
  src: string
  alt: string
}

export const Img = styled.img<IImg>`
  ${elementStyle}
`
