/* eslint-disable no-confusing-arrow */
/* eslint-disable operator-linebreak */
import cx from 'classnames'

export const flexWrap = (...className: string[]): ReturnType<typeof cx> =>
  cx('d-flex', 'flex-wrap', ...className)

export const createToggleColor =
  (activeColor: string, defaultColor: string) =>
  (active?: boolean): string =>
    active ? activeColor : defaultColor
