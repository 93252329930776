import type { ContainerProps as BSContainerProps } from 'react-bootstrap'

// import PropTypes from 'prop-types'
import { Container as BSContainer } from 'react-bootstrap'
import styled from 'styled-components'

import { elementStyle } from 'styles/element-style'

export interface IContainerProps extends BSContainerProps, IElement {}

const StyledContainer = styled(BSContainer)`
  ${elementStyle}
`

export const Container: React.FC<IContainerProps> = (props: IContainerProps) => (
  <StyledContainer {...props} />
)

Container.propTypes = {}

Container.defaultProps = {}

export default Container
