import styled, { css } from 'styled-components'

import { Div } from 'components/common/atoms/div'
import { elementStyle } from 'styles/element-style'

const baseModalCss = css`
  display: block;
  position: fixed;
  background-color: white;
  z-index: 12;
`

const desktopModalCss = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  border-radius: 8px;
  overflow: auto;
  max-height: 100%;
  ${baseModalCss}
  ${elementStyle}
`

const mobileModalCss = css`
  width: 100%;
  height: calc(100% - 50px);
  top: 50px;
  left: 0;
  overflow: auto;
  ${baseModalCss}
`

const hideModalCss = css`
  display: none;
`

export const ModalWrapper = styled.div<IElement>`
  @media only screen and (min-width: 992px) {
    ${({ $hideDesktop }) => ($hideDesktop ? hideModalCss : desktopModalCss)}
  }
  @media only screen and (max-width: 991px) {
    ${({ $hideMobile }) => ($hideMobile ? hideModalCss : mobileModalCss)}
  }
  ${elementStyle}
`

export const CloseButton = styled.i`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 128;
  cursor: pointer;
`

const showOverlayCss = css`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: #000;
  z-index: 11;
`

const hideOverlayCss = css`
  display: none;
`

export const OverlayWrapper = styled(Div)`
  @media only screen and (min-width: 992px) {
    ${({ $hideDesktop }) => ($hideDesktop ? hideOverlayCss : showOverlayCss)}
  }
  @media only screen and (max-width: 991px) {
    ${({ $hideMobile }) => ($hideMobile ? hideOverlayCss : showOverlayCss)}
  }
`
