import PropTypes from 'prop-types'

import { COLOR, TEXT_SEMI_BOLD } from 'styles/constants'

import { Div, H3, Body } from '../../atoms'
import { Currency } from '../../molecules/currency'

import { Card, CoverImage, TextBottomSection, TextSection, StyledA } from './style'

interface IListingCard {
  address: string
  propertyType: string
  rentAmount: number
  photo: string
  detail: string
  url: string
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export const ListingCard: React.FC<IListingCard> = ({
  rentAmount,
  address,
  propertyType,
  detail,
  photo,
  url,
  onClick,
}) => (
  <Card $width="216px" $height="344px" $mWidth="320px" $mHeight="304px" onClick={onClick}>
    <Div $height="152px" $mHeight="160px">
      <StyledA href={url}>
        <CoverImage src={photo} alt="card-cover-image" />
      </StyledA>
    </Div>
    <TextSection>
      <StyledA href={url}>
        <H3 $mTextStyle="h2" $color={COLOR.BLUE_LV2}>
          <Currency value={rentAmount} />
        </H3>
      </StyledA>
      <StyledA href={url}>
        <Body $mWeight={TEXT_SEMI_BOLD} $color={COLOR.GREY_LV1} $mt="2" $truncate={3}>
          {address}
        </Body>
      </StyledA>
      <TextBottomSection>
        <StyledA href={url}>
          <Body $textStyle="body-small" $mTextStyle="body" $color={COLOR.GREY_LV2} $block>
            {propertyType}
          </Body>
        </StyledA>
        <StyledA href={url}>
          <Body $textStyle="body-small" $mTextStyle="body" $color={COLOR.GREY_LV2} $block>
            {detail}
          </Body>
        </StyledA>
      </TextBottomSection>
    </TextSection>
  </Card>
)

ListingCard.propTypes = {
  rentAmount: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
