import type { IListingCardData } from 'store/listing-list/types'

import { useRouter } from 'next/router'

import { Body, H3, ListingCard } from 'components'
import { Pagination } from 'components/common/organisms/pagination'
import env from 'env'
import { useSelector } from 'store'
import { selectAgentProfileOwnerId } from 'store/agent-profile'
import {
  selectListingListForListingCard,
  selectListingListPagination,
} from 'store/listing-list/selectors'
import tracker, { events } from 'tracker'

import { parsePhotoGcsProtocol, resizePhoto } from '../../../utils/image-proxy/path'

import { ListingCardBox, WrappedContent, WrappedPagination } from './style'

type ActiveListingProps = {
  isDisplayFooter?: boolean
}
export const ActiveListing: React.FC<ActiveListingProps> = ({ isDisplayFooter = false }) => {
  const router = useRouter()
  const listings = useSelector(selectListingListForListingCard)
  const agentId = useSelector(selectAgentProfileOwnerId)
  const paginationData = useSelector(selectListingListPagination)
  const openLink =
    ({ url, listingId }: { url: string; listingId: string }) =>
    (e: React.MouseEvent) => {
      e.preventDefault()
      tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_VIEW_LISTING_PAGE, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        listing_id: listingId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        agent_id: agentId,
      })
      window.open(url, '_self')
    }

  const onPageChange = async (current: number) => {
    await router.replace({ query: { ...router.query, listingPage: current } }, undefined, {
      scroll: false,
    })
  }
  const calSpacingDependOnFooter = (totalPage: number): number => {
    const defaultSpacing = isDisplayFooter ? 0 : 30
    const isShowPagination = totalPage > 1
    return isShowPagination ? defaultSpacing + 50 : defaultSpacing + 70
  }
  const calSpacingMobileDependOnFooter = (totalPage: number): string => {
    const mobilePadding = 20
    const bottomSpacePagination = totalPage > 1 ? 20 : 0
    const marginBottom = calSpacingDependOnFooter(totalPage) - mobilePadding + bottomSpacePagination
    return `0 0 ${marginBottom}px`
  }

  const parsePhoto = (photo: string) =>
    resizePhoto(env.IMAGE_PROXY_HOST, parsePhotoGcsProtocol(photo))

  return (
    <>
      <H3 $mTextStyle="h2" $mt="32" $mMargin="24px 0 0">
        Active Listings ({paginationData.total})
      </H3>
      {!paginationData.total ? (
        <Body
          $block
          $mt="10"
          $mb={calSpacingDependOnFooter(paginationData?.maxPage ?? 1)}
          $mMargin={calSpacingMobileDependOnFooter(paginationData?.maxPage ?? 1)}
        >
          There is no active listing yet.
        </Body>
      ) : (
        <WrappedContent
          $mb={calSpacingDependOnFooter(paginationData?.maxPage ?? 1)}
          $mMargin={calSpacingMobileDependOnFooter(paginationData?.maxPage ?? 1)}
        >
          <ListingCardBox>
            {listings.map((data: IListingCardData) => (
              <ListingCard
                key={data.id}
                rentAmount={data.rentAmount}
                address={data.propertyAddress}
                propertyType={data.propertyType}
                detail={data.propertyDetail}
                photo={parsePhoto(data.photo)}
                url={data.publicListingUrl}
                onClick={openLink({ url: data.publicListingUrl, listingId: data.id })}
              />
            ))}
          </ListingCardBox>
          <WrappedPagination>
            <Pagination
              isAllPage={false}
              total={paginationData?.maxPage ?? 1}
              page={paginationData.page}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPageChange={onPageChange}
            />
          </WrappedPagination>
        </WrappedContent>
      )}
    </>
  )
}
