import { createGlobalStyle } from 'styled-components'

import { COLOR } from 'styles/constants'

export const RentSpreeSweetalertGlobalStyle = createGlobalStyle`
  p, div, span, label, h1, h2, h3, h4, h5, h6, button {
    font-family: 'Poppins';
  }

  .rsp-swal-container {
    font-family: 'Poppins';

    .swal2-popup {
      max-width: 456px;
      padding: 8px;
      border-radius: 8px;

      @media (max-width:991px) {
        max-width: 336px;
      }
    }

    .swal2-icon {
      margin: 56px auto 0;
    }

    .swal2-title {
      padding: 32px 0 0;
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
      color: ${COLOR.GREY_LV1};

      @media (max-width:991px) {
        font-size: 22px;
        line-height: 32px;
      }

    }

    .swal2-html-container {
      font-size: 16px;
      line-height: 28px;
      margin: 16px 64px 0;
      color: ${COLOR.GREY_LV1};
      font-weight: 400;

      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 24px;
        margin: 16px 60px 0;
      }
    }

    .swal2-actions {
      gap: 24px;
      margin: 40px 56px 48px 56px;

      @media (max-width: 991px) {
        flex-direction: column-reverse;
        gap: 8px;
        width: 208px;
        margin-top: 24px;
        margin-bottom: 32px;
      }
    }

    .swal2-success-ring {
      color: ${COLOR.GREEN_LV3};
      border-color: ${COLOR.GREEN_LV3}!important;
      top: -0.375em!important;
      left: -0.375em!important;
      border: .375em solid ${COLOR.GREEN_LV3}!important;
    }

    .swal2-icon.swal2-success [class^=swal2-success-line] {
      z-index: 3;
      height: 0.5em;
      border-radius: 1em;
    }
    
    .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
      width: 1.7em;
    }

    .swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
      width: 3em;
    }

    .swal2-success-line-long, .swal2-success-line-tip {
      background-color: ${COLOR.GREEN_LV3}!important;
    }

    .swal2-close {
      font-family: 'Courier New', Courier, monospace;
      color: ${COLOR.GREY_LV1};
      opacity: .5;

      &:hover {
        color: ${COLOR.GREY_LV1};
        opacity: .75;
      }

      &:focus {
        opacity: 1;
      }
    }
  }
  
  .go-to-messages-container {
    .swal2-actions {
      gap: 24px;
      margin: 40px 0 48px 0;

      @media (max-width: 991px) {
        flex-direction: column-reverse;
        gap: 8px;
        width: 208px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;
      } 
    }
  }

  .rsp-swal-btn {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    padding: 8px 12px;
    border-radius: 20px;
    font-family: 'Poppins';
    min-width: 136px;

    @media (max-width: 991px) {
        width: 208px;
      }
  }

  .rsp-swal-cancel-btn {
    color: ${COLOR.BLUE_LV2};
    background: ${COLOR.WHITE};
    border: 1px solid ${COLOR.BLUE_LV2};
    font-size: 16px;

    &:hover {
      color: ${COLOR.BLUE_LV5};
      border: 1px solid ${COLOR.BLUE_LV5};
    }
  }

  .rsp-swal-deny-btn {
    color: ${COLOR.BLUE_LV5};
    background-color: ${COLOR.WHITE};
    border-width: 0;

    &:hover {
      color: ${COLOR.BLUE_LV5};
    }
  }

  .rsp-swal-confirm-btn {
    color: ${COLOR.WHITE};
    border-width: 0px;
    background: ${COLOR.GRADIENT_BLUE_LV1};

    &:hover {
      background: ${COLOR.BLUE_LV2};
    }
  }

  .go-to-messages-btn {
    padding: 8px 24px;
  }
`
