import styled from 'styled-components'

import { Div } from 'components/common/atoms'

export const ListingCardBox = styled(Div)`
  margin-top: 24px;
  display: flex;
  flex-flow: row wrap;
  column-gap: 24px;
  row-gap: 24px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`

export const WrappedPagination = styled(Div)`
  text-align: center;
`

export const WrappedContent = styled(Div)`
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px;`}
  @media (max-width: 991px) {
    ${({ $mMargin }) => $mMargin && `margin: ${$mMargin};`}
  }
`
