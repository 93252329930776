import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

import { Div, Img } from 'components'
import tracker, { events } from 'tracker'

export const SocialIconWrapper = styled.div`
  display: flex;
  margin-top: 5px;

  @media (max-width: 991px) {
    justify-content: left;
  }
`

export const SocialIconItem = styled.div`
  margin: 0 12px;

  @media (max-width: 991px) {
    margin-right: 24px;
    margin-left: 0;
  }
`

interface ISocialContactProps {
  contacts: {
    facebook?: string | null
    instagram?: string | null
    twitter?: string | null
    linkedin?: string | null
    tiktok?: string | null
  }
}

const SocialContact: React.FC<ISocialContactProps> = ({ contacts }) => {
  const handleClickSocialLink = (socialType: string) => {
    tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_SOCIAL_BUTTON.replace('%socialType%', socialType))
  }

  return (
    <Div $mt="16" $mMargin="16px 0 0 0" data-testid="socail-container">
      {!every(Object.values(contacts), isEmpty) && (
        <SocialIconWrapper>
          {contacts?.facebook && (
            <SocialIconItem>
              <a
                href={contacts?.facebook}
                onClick={() => handleClickSocialLink('facebook')}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  src="./facebook.svg"
                  alt="facebook"
                  $width="30px"
                  $height="30px"
                  $mWidth="40px"
                  $mHeight="40px"
                />
              </a>
            </SocialIconItem>
          )}
          {contacts?.twitter && (
            <SocialIconItem>
              <a
                href={contacts?.twitter}
                onClick={() => handleClickSocialLink('twitter')}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  src="./twitter.svg"
                  alt="twitter"
                  $width="30px"
                  $height="30px"
                  $mWidth="40px"
                  $mHeight="40px"
                />
              </a>
            </SocialIconItem>
          )}
          {contacts?.instagram && (
            <SocialIconItem>
              <a
                href={contacts?.instagram}
                onClick={() => handleClickSocialLink('instagram')}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  src="./instagram.svg"
                  alt="instagram"
                  $width="30px"
                  $height="30px"
                  $mWidth="40px"
                  $mHeight="40px"
                />
              </a>
            </SocialIconItem>
          )}
          {contacts?.linkedin && (
            <SocialIconItem>
              <a
                href={contacts?.linkedin}
                onClick={() => handleClickSocialLink('linkedin')}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  src="./linkedin.svg"
                  alt="linkedin"
                  $width="30px"
                  $height="30px"
                  $mWidth="40px"
                  $mHeight="40px"
                />
              </a>
            </SocialIconItem>
          )}
          {contacts?.tiktok && (
            <SocialIconItem>
              <a
                href={contacts?.tiktok}
                onClick={() => handleClickSocialLink('tiktok')}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  src="./tiktok.svg"
                  alt="tiktok"
                  $width="30px"
                  $height="30px"
                  $mWidth="40px"
                  $mHeight="40px"
                />
              </a>
            </SocialIconItem>
          )}
        </SocialIconWrapper>
      )}
    </Div>
  )
}

export { SocialContact }
