import PropTypes from 'prop-types'

import { Span } from './span'

interface IText extends ISpan {
  $size?: string
  $lineHeight?: string
  $bold?: boolean
  className?: string
}

export const Text: React.FC<React.PropsWithChildren<IText>> = ({ $bold, children, ...style }) => (
  <Span {...style} $weight={$bold ? '600' : '400'}>
    {children}
  </Span>
)

Text.propTypes = {
  $size: PropTypes.string,
  $lineHeight: PropTypes.string,
  $bold: PropTypes.bool,
}

Text.defaultProps = {
  $size: '16px',
  $lineHeight: '20px',
  $bold: undefined,
}
