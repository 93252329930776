import type { InputWithLabelElementInterface } from './types'

import { Body, Div, Img, Span, TextInput } from 'components/common/atoms'
import { COLOR } from 'styles/constants'

type TextInputWithLabelProps = InputWithLabelElementInterface<HTMLInputElement>

export const TextInputWithLabel: React.FC<TextInputWithLabelProps> = ({
  id,
  label,
  name,
  value,
  error,
  disabled,
  isRequired,
  showErrorIcon,
  onChange,
  onBlur,
  placeholder,
  ...props
}) => (
  <Div id={`${id}Group`} {...props}>
    <Span as="label" id={`${id}Label`} htmlFor={id} $textStyle="body" $block>
      {label}
      {isRequired && <Body $color={COLOR.RED_LV2}> *</Body>}
    </Span>
    <TextInput
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
      $error={!!error}
      $width="100%"
      $margin="8px 0 0"
      $display="block"
    />
    {error && typeof error === 'string' && (
      <Div className="d-flex align-items-center" $mt="8">
        {showErrorIcon && (
          <Img
            src="../profile/icon/circle-exclaimation-outline.svg"
            alt="text-input-error-icon"
            $width="16px"
            $height="16px"
            $mr="8"
          />
        )}
        <Body $textStyle="caption" $color={COLOR.RED_LV2}>
          {error}
        </Body>
      </Div>
    )}
  </Div>
)
