import { Img } from 'components/common/atoms/img'

interface IProps extends IElement {
  id?: string
  src: string
}

export const ImageIcon: React.FC<IProps> = ({ id, src, ...restProps }) => (
  <Img src={src} alt={`icon${id ? `-${id}` : ''}`} id={id} {...restProps} />
)
