import type { FlattenSimpleInterpolation } from 'styled-components'

import { css } from 'styled-components'

import { TEXT_BOLD, TEXT_REGULAR, TEXT_SEMI_BOLD } from 'styles/constants'

export const MOBILE_H1 = css`
  font-weight: ${TEXT_BOLD};
  font-size: 32px;
  line-height: 48px;
`

export const MOBILE_H2 = css`
  font-weight: ${TEXT_BOLD};
  font-size: 22px;
  line-height: 32px;
`

export const MOBILE_H3 = css`
  font-weight: ${TEXT_BOLD};
  font-size: 16px;
  line-height: 24px;
`

export const MOBILE_SUBTITLE1 = css`
  font-weight: ${TEXT_REGULAR};
  font-size: 16px;
  line-height: 24px;
`

export const MOBILE_SUBTITLE2 = css`
  font-weight: ${TEXT_BOLD};
  font-size: 14px;
  line-height: 24px;
`

export const MOBILE_BUTTON = css`
  font-weight: ${TEXT_SEMI_BOLD};
  font-size: 14px;
  line-height: 24px;
`

export const MOBILE_BODY = css`
  font-size: 14px;
  line-height: 24px;
`

export const MOBILE_BODY_SMALL = css`
  font-size: 12px;
  line-height: 16px;
`

export const MOBILE_CAPTION = css`
  font-size: 12px;
  line-height: 16px;
`

export function selectMobileTypographyStyle(textStyle?: string): FlattenSimpleInterpolation | null {
  switch (textStyle) {
    case 'h1':
      return MOBILE_H1
    case 'h2':
      return MOBILE_H2
    case 'h3':
      return MOBILE_H3
    case 'subtitle1':
      return MOBILE_SUBTITLE1
    case 'subtitle2':
      return MOBILE_SUBTITLE2
    case 'button':
      return MOBILE_BUTTON
    case 'body':
      return MOBILE_BODY
    case 'body-small':
      return MOBILE_BODY_SMALL
    case 'caption':
      return MOBILE_CAPTION
    default:
      return null
  }
}
