import styled from 'styled-components'

import { Div, Title, Ul, Li } from 'components/common/atoms'
import { COLOR } from 'styles/constants'

interface IDivStyleInterface extends IElement, IFlexItem {
  className?: string
}

interface IListWithHeaderInterface {
  headerColor?: string
  headerText: string
  headerStyle?: ISpan
  listItem: IFooterLink[]
  linkColor?: string
  linkHoverColor?: string
  headerSpacing?: string
  spacingBetweenContent?: string
  divStyle?: IDivStyleInterface
}

const Link = styled.a<{ color?: string; hoverColor?: string }>`
  color: ${({ color }) => color || COLOR.BLACK};
  transition: all ease-in-out 300ms;
  font-size: 14px;
  --webkit-transition: all ease-in-out 300ms;
  :hover {
    color: ${({ hoverColor }) => hoverColor || COLOR.BLACK};
  }
  @media (max-width: 991px) {
    font-size: 12px;
  }
`

export const ListWithHeader: React.FC<IListWithHeaderInterface> = ({ ...props }) => (
  <Div {...props.divStyle}>
    <Title $color={props.headerColor} {...props.headerStyle} $size="16px" $lineHeight="28px">
      {props.headerText}
    </Title>
    <Ul $mt={props.headerSpacing}>
      {props.listItem.map(({ displayText, link, onClick }: IFooterLink) => (
        <Li $mb={props.spacingBetweenContent} key={`${displayText}-${link}`}>
          <Link
            onClick={onClick}
            href={link}
            target="_blank"
            color={props.linkColor}
            hoverColor={props.linkHoverColor}
          >
            {displayText}
          </Link>
        </Li>
      ))}
    </Ul>
  </Div>
)
