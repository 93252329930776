export const abbreviateName = (name: string): string => `${name[0]}.`

export const shortenName = (fullName: string): string => {
  const splitName = fullName.split(' ')
  if (splitName.length > 1) {
    const firstName = splitName[0]
    const lastName = splitName[splitName.length - 1]
    return `${firstName} ${lastName && abbreviateName(lastName)}`
  }
  return fullName
}
