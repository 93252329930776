import { css } from 'styled-components'

export const flexItemStyle = css<IFlexItem>`
  ${props => props.$flexOrder && `order: ${props.$flexOrder}`};
  ${props => props.$flexGrow && `flex-grow: ${props.$flexGrow}`};
  ${props => props.$flexShrink && `flex-shrink: ${props.$flexShrink}`};
  ${props => props.$flexBasis && `flex-basis: ${props.$flexBasis}`};
  ${props => props.$flex && `flex: ${props.$flex}`};
  ${props => props.$alignSelf && `align-self: ${props.$alignSelf}`};
  ${props => props.$gap && `gap: ${props.$gap}`};
  ${props => props.$flexDirection && `flex-direction: ${props.$flexDirection}`};
  ${props => props.$justifyContent && `justify-content: ${props.$justifyContent}`};
  ${props => props.$alignItems && `align-items: ${props.$alignItems}`};

  @media (max-width: 991px) {
    ${props => props.$mFlexOrder && `order: ${props.$mFlexOrder}`};
    ${props => props.$mFlexGrow && `flex-grow: ${props.$mFlexGrow}`};
    ${props => props.$mFlexShrink && `flex-shrink: ${props.$mFlexShrink}`};
    ${props => props.$mFlexBasis && `flex-basis: ${props.$mFlexBasis}`};
    ${props => props.$mFlex && `flex: ${props.$mFlex}`};
    ${props => props.$mAlignSelf && `align-self: ${props.$mAlignSelf}`};
    ${props => props.$mGap && `gap: ${props.$mGap}`};
    ${props => props.$mFlexDirection && `flex-direction: ${props.$mFlexDirection}`};
    ${props => props.$mJustifyContent && `justify-content: ${props.$mJustifyContent}`};
    ${props => props.$mAlignItems && `align-items: ${props.$mAlignItems}`};
  }
`

/* NOTE: Defined all props for generate elementPropKeys */
const flexItemProps: Required<IFlexItem> = {
  $flexOrder: '',
  $flexGrow: '',
  $flexShrink: '',
  $flexBasis: '',
  $flex: '',
  $alignSelf: '',
  $gap: '',
  $flexDirection: '',
  $justifyContent: '',
  $alignItems: '',
  $mFlexOrder: '',
  $mFlexGrow: '',
  $mFlexShrink: '',
  $mFlexBasis: '',
  $mFlex: '',
  $mAlignSelf: '',
  $mGap: '',
  $mFlexDirection: '',
  $mJustifyContent: '',
  $mAlignItems: '',
}

export const flexItemPropKeys = Object.keys(flexItemProps)
