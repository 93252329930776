import type { IAlertProps, AlertVariant } from './types'

import { Icon } from '@rentspree/component-v2/dist/icon'
import { Alert as BsAlert } from 'react-bootstrap'
import styled, { css } from 'styled-components'

import { ImageIcon } from 'components/common/atoms/icon'
import { COLOR, SHADOW_LV2 } from 'styles/constants'
import { elementStyle } from 'styles/element-style'
import { flexItemStyle } from 'styles/flex-item-style'

function variantCss(variant: AlertVariant | undefined) {
  switch (variant) {
    case 'success':
      return css`
        background-color: ${COLOR.GREEN_PALE};
        color: ${COLOR.DARK_GREEN};
      `
    case 'error':
      return css`
        background-color: ${COLOR.RED_PALE};
        color: ${COLOR.NEGATIVE_RED};
      `
    case 'warning':
      return css`
        background-color: ${COLOR.YELLOW_PALE};
        color: ${COLOR.DARK_YELLOW_BRIGHTER};
      `
    case 'info':
      return css`
        background-color: ${COLOR.BLUE_LV6};
        color: ${COLOR.BLUE_LV2};
      `
    case 'grey':
      return css`
        background-color: ${COLOR.GREY_LV5};
        color: ${COLOR.GREY_LV1};
      `
    case 'general':
    default:
      return css`
        background-color: ${COLOR.WHITE};
        color: ${COLOR.GREY_LV1};
      `
  }
}

function variantIcon(variant: AlertVariant | undefined) {
  switch (variant) {
    case 'success':
      return '../profile/icon/check.svg'
    case 'error':
      return '../profile/icon/error.svg'
    case 'warning':
      return '../profile/icon/warning.svg'
    case 'info':
    case 'grey':
    case 'general':
    default:
      return '../profile/icon/circle-exclaimation-outline.svg'
  }
}

const AlertContent = styled.div<IFlexItem>`
  ${flexItemStyle}
`

export const Alert: React.FC<IAlertProps> = styled(
  ({ children, variant, noClose, onClose, ...props }: IAlertProps) => (
    <BsAlert {...props}>
      <ImageIcon src={variantIcon(variant)} $width="24px" $height="24px" />
      <AlertContent $alignSelf="center">{children}</AlertContent>
      {noClose ? null : <Icon icon="close" size="md" onClick={onClose} />}
    </BsAlert>
  ),
)`
  display: flex;
  column-gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border-width: 0;
  ${({ variant }) => variantCss(variant)}
  ${({ $shadow }) => $shadow && `box-shadow: ${SHADOW_LV2}`};
  ${elementStyle}
`
