import PropTypes from 'prop-types'

import { Span } from './span'

interface ITitle extends ISpan {
  $size?: string
  $lineHeight?: string
}

export const Title: React.FC<React.PropsWithChildren<ITitle>> = ({
  $size,
  $lineHeight,
  children,
  ...style
}) => (
  <Span {...style} $weight="600" $lineHeight={$lineHeight} $size={$size} $block>
    {children}
  </Span>
)

Title.propTypes = {
  $size: PropTypes.string,
  $lineHeight: PropTypes.string,
}

Title.defaultProps = {
  $size: '18px',
  $lineHeight: '24px',
}
