import styled from 'styled-components'

import { Hr } from 'components/common/atoms'
import { COLOR } from 'styles/constants'

export const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`

export const SeparateLine = styled(Hr)`
  color: ${COLOR.GREY_LV2};
  margin: 20px 0;
`

export const ReviewSection = styled.div`
  width: 100%;
`

export const ButtonGroup = styled.div<{ embedded?: boolean }>`
  width: 100%;

  @media (min-width: 992px) {
    margin: 20px auto;

    button {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    width: 100vw;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 20px;
    ${({ embedded }) =>
      !embedded && [`background: ${COLOR.WHITE};`, 'box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);']}
  }
`

export const Container = styled.div<{ embedded?: boolean }>`
  @media (max-width: 991px) {
    min-height: ${({ embedded }) => (embedded ? '100vh' : 'calc(100vh - 57px)')};
  }
`

export const ScrollContentWrapper = styled.div<{ embedded?: boolean }>`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  @media (max-width: 991px) {
    overflow-y: auto;
    ${({ embedded }) =>
      `max-height: ${embedded ? 'calc(100vh - 135px)' : 'calc(100vh - 57px - 135px)'};
      padding: ${embedded ? '20px 25px 10px 25px' : '30px 25px 10px 25px'};
    `};
  }
`

export const ComplimentOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px 0 20px 0;
`

export const SubHeader = styled.p`
  color: ${COLOR.GREY_LV1};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
`
export const ReviewerName = styled.p`
  color: ${COLOR.GREY_LV1};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  margin-left: 6px;
`

export const StarRatingWrapper = styled.div`
  padding: 11.94px 0 21.94px 0;
`

export const TextAreaWrapper = styled.div`
  padding: 10px 0;
`

export const GuidelineLink = styled.a`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: ${COLOR.BLUE_LV5};
`

export const AgentBadgeContainer = styled.div``
