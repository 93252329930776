import { SimpleTooltip } from '@rentspree/ssr-component-v2'
import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'

import { ButtonLink, H2, Img, Span } from 'components'
import { useSelector } from 'store'
import { useTracker } from 'store/@hooks/use-tracker'
import {
  selectAgentProfileFullName,
  selectAgentProfileSlug,
  selectAgentVerifiedStatus,
} from 'store/agent-review-page'
import { AGENT_REVIEW } from 'tracker/events'

export const AgentTitleName: React.FC = () => {
  const router = useRouter()
  const track = useTracker()
  const slug = useSelector(selectAgentProfileSlug)
  const fullName = useSelector(selectAgentProfileFullName)
  const isAgentVerified = useSelector(selectAgentVerifiedStatus)

  if (!isAgentVerified) {
    return (
      <span className="d-flex align-items-center">
        <H2>{isEmpty(fullName) ? 'Name' : fullName}</H2>
        <ButtonLink
          $ml="12.5"
          $textStyle="body-small"
          $semiBold
          text="View Profile"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => router.push(`/profile/${slug}`)}
        />
      </span>
    )
  }

  const badgeIcon = (
    <span className="d-flex align-items-center">
      <Img src="/profile/verified-badge.svg" alt="Verified" $ml="15" />
    </span>
  )

  const handleOpenAgentProfile = async () => {
    track(AGENT_REVIEW.EVENT.CLICK_VIEW_PROFILE)
    await router.push(`/profile/${slug}`)
  }

  return (
    <span className="d-flex align-items-center">
      <H2>{fullName}</H2>
      <SimpleTooltip
        bubbleWidth="unset"
        position="TOP"
        background="black"
        color="white"
        componentTitle={badgeIcon}
      >
        <Span $size="12px" color="white" $weight="400">
          Account verified
        </Span>
      </SimpleTooltip>
      <ButtonLink
        $ml="12.5"
        $textStyle="body-small"
        $semiBold
        text="View Profile"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleOpenAgentProfile}
      />
    </span>
  )
}
