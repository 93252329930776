import styled from 'styled-components'

interface IScrollList {
  disabled: boolean
}

const ScrollList = styled.div<IScrollList>`
  overflow-y: scroll;
  ${({ disabled }) =>
    disabled &&
    `pointer-events: none;
     overflow:hidden; 
     filter: grayscale(100%);
     opacity: 0.8;`}
`

export interface IListProps {
  dataSource: any[]
  renderItem: (dataSource: any, index: number) => React.ReactNode
  isEnabled?: boolean
}

export const List: React.FC<IListProps> = ({ dataSource, renderItem }) => (
  <>{dataSource.map((data: any, index: number): React.ReactNode => renderItem(data, index))}</>
)

export const ScrollableList: React.FC<IListProps> = ({
  dataSource,
  renderItem,
  isEnabled = true,
}) => (
  <ScrollList disabled={isEnabled}>
    <List dataSource={dataSource} renderItem={renderItem} />
  </ScrollList>
)
