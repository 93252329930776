import styled from 'styled-components'

import { textStyle } from 'styles/text-style'

const StyledH3 = styled.h3`
  ${textStyle}
`

export const H3: React.FC<React.PropsWithChildren<ISpan>> = ({ children, ...props }) => (
  <StyledH3 $textStyle="h3" {...props}>
    {children}
  </StyledH3>
)
