import { css } from 'styled-components'

export const LARGE_BUTTON_BORDER_RADIUS = '36px'
export const XL_BUTTON_BORDER_RADIUS = '30px'
export const XL_BUTTON_PADDING = '50px'
export const BUTTON_HEIGHT = '40px'
export const SMALL_BUTTON_PADDING = '25px'
export const SMALL_BUTTON_BORDER_RADIUS = '20px'
export const SHADOW_LV1 = '0 2px 7px 0 rgba(0, 0, 0, 0.1)'
export const SHADOW_LV2 = '0 2px 15px 0 rgba(0, 0, 0, 0.25)'

export const RSP_FONT = {
  bodySmallSize: '12px',
  bodyNormalSize: '14px',
  bodyTitleSize: '16px',
  largeTitleSize: '24px',
  normalWeight: 400,
  semiBoldWeight: 600,
}

export const BUTTON_SHADOW = '0 15px 20px 0 rgba(0, 0, 0, 0.1)'
export const NORMAL_SHADOW = '0 2px 7px 0 rgba(0, 0, 0, 0.1)'
export const HOVER_SHADOW = '0 2px 15px 0 rgba(0, 0, 0, 0.25)'
export const DROPDOWN_SHADOW = '0 0 20px 0 rgba(0, 0, 0, 0.15)'
export const MODAL_OVERLAY = 'rgba(0, 0, 0, 0.5)'

export const ANIMATE = {
  FAST: '0.2s',
  NORMAL: '0.5s',
  SLOW: '1s',
}

export const FLEX_CENTER = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const INLINE_FLEX_CENTER = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const TOOLTIP_BASIC_STEP_WIDTH = 233
export const TOOLTIP_COMPLETE_STEP_WIDTH = 272
export const SHARE_BUTTON_STYLES: Record<string, { icon: string; color: string }> = {
  facebook: {
    icon: '/profile/facebook.svg',
    color: '#3b5998',
  },
  twitter: {
    icon: '/profile/twitter.svg',
    color: '#55acee',
  },
  email: {
    icon: '/profile/icon-email-circle.svg',
    color: '#333333',
  },
  linkedin: {
    icon: '/profile/linkedin.svg',
    color: '#0077B5',
  },
} as const

export { COLOR } from './color'

export * from './typography'
