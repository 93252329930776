import type { IContactAgentMessageFormProps } from './types'

import { Button } from '@rentspree/component-2023.components.atoms.button'

import { Body, ButtonIcon, H3, TextArea } from 'components'

import { LegalTextFooter } from './footer'

export const ContactAgentMessageForm: React.FC<IContactAgentMessageFormProps> = ({
  agentFullName,
  isLoading = false,
  message,
  onMessageChange,
  onSubmit,
}) => (
  <>
    <H3 $mTextStyle="h2" $mb="8" $mMargin="0 0 24px">
      Contact {agentFullName}
    </H3>
    <Body $mb="4" $mMargin="0 0 8px" $block>
      Message
    </Body>
    <TextArea
      id="contact-agent-message-textarea"
      value={message}
      onChange={onMessageChange}
      disabled={isLoading}
      $height="122px"
      $mHeight="160px"
      $width="100%"
      $noResize
      $display="block"
    />
    <Button
      id="submit-contact-agent-message"
      variant="contained"
      size="medium"
      sx={{ width: '100%' }}
      disabled={isLoading}
      onClick={onSubmit}
    >
      {isLoading ? <ButtonIcon className="fas fa-circle-notch fa-spin" /> : ' Send'}
    </Button>
    <LegalTextFooter />
  </>
)
