import { isEmpty } from 'lodash'

import { Col, Li, ShowMoreText, Div, H3, Hr, Body } from 'components'
import { useSelector } from 'store'
import { selectAgentProfileExperience } from 'store/agent-profile'
import { flexWrap } from 'styles/utils'

const TITLE = 'Experience & Certifications'

interface IExperience {
  onShowMoreTextReady?: (v: number) => void
}

export const Experience: React.FC<IExperience> = ({ onShowMoreTextReady }) => {
  const { agentLicenses, brokerageLicense, certificates, year } = useSelector(
    selectAgentProfileExperience,
  )

  const hasYears = !!year
  const hasAgentLicenses = !isEmpty(agentLicenses?.[0]?.number)
  const hasBrokerageLicenses = !isEmpty(brokerageLicense)
  const hasCertificates = !isEmpty(certificates)
  if (!hasYears && !hasAgentLicenses && !hasBrokerageLicenses && !hasCertificates) return null
  return (
    <Div $mb="32" $mMargin="0 0 24px">
      <Hr />
      <Div $mt="32" $mMargin="24px 0 0px">
        <H3>{TITLE}</H3>
      </Div>
      <Div className={flexWrap()} $mt="24" $mMargin="16px 0 0">
        {hasYears && (
          <Col $width="224px" xs={12} $mPadding="0">
            <Body $bold>Years of Experience</Body>
            <Body $mt="4" $block>
              {year}
            </Body>
          </Col>
        )}
        {hasAgentLicenses && (
          <Col $width="224px" $mMargin="16px 0 0 0" xs={12} $mPadding="0">
            <Body $bold>Real Estate License</Body>
            <Body $mt="4" $block>
              {agentLicenses?.[0]?.number}
            </Body>
          </Col>
        )}
        {hasBrokerageLicenses && (
          <Col $width="151px" $mMargin="16px 0 0 0" xs={12} $mPadding="0">
            <Body $bold>Brokerage License</Body>
            <Body $mt="4" $block>
              {brokerageLicense}
            </Body>
          </Col>
        )}
      </Div>
      {hasCertificates && (
        <>
          <Div className={flexWrap()} $mt="24" $mMargin="16px 0 0 0">
            <Body $mPadding="0" $bold>
              Real Estate Certifications
            </Body>
          </Div>
          <ShowMoreText line={4} lineOffset={2} $mt="4" onReady={onShowMoreTextReady}>
            <Div className={flexWrap()}>
              <Col as="ul" $pl="30" $mb="0">
                {certificates?.map(certificate => (
                  <Li key={certificate.number}>
                    <Body>{certificate.name} </Body>
                    <Body>{certificate.number}</Body>
                  </Li>
                ))}
              </Col>
            </Div>
          </ShowMoreText>
        </>
      )}
    </Div>
  )
}
