import styled from 'styled-components'

export const ButtonFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 34px;
`

export const ButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonArrowWrapper = styled.div`
  font-size: 20px;
`
