import type { ImageProxyInterface } from './interfaces'
import type { SizeOptionsType, ResizeOptionsType, ProcessingOptions } from './types'

import { ResizeProcessingOption, SizeProcessingOption } from './processing-options'
import { SourceUrl } from './source-url/source-url'

export class ImageProxy implements ImageProxyInterface {
  processingOptions: ProcessingOptions = {
    resizeProcessingOptions: new ResizeProcessingOption({}),
    sizeProcessingOptions: new SizeProcessingOption({}),
  }

  imageProxyHost: string

  processedOptions: { [key: string]: string } = {}

  constructor(imageProxyHost: string) {
    this.imageProxyHost = `${imageProxyHost}/insecure`
  }

  parseProcessingOptions(): string {
    return Object.values(this.processedOptions).join('/')
  }

  resize(resizeOptions: ResizeOptionsType): ImageProxy {
    const optionsParsed: string = this.processingOptions.resizeProcessingOptions
      ?.update(resizeOptions)
      ?.parseOptions()

    this.processedOptions.resize = optionsParsed
    return this
  }

  size(sizeOptions: SizeOptionsType): ImageProxy {
    const optionsParsed: string = this.processingOptions.sizeProcessingOptions
      ?.update(sizeOptions)
      ?.parseOptions()

    this.processedOptions.size = optionsParsed
    return this
  }

  generateUrl(imageSource: string, encode: boolean = false, extension?: string): string {
    const source = new SourceUrl(imageSource)
    return [
      this.imageProxyHost,
      this.parseProcessingOptions(),
      source.getImageSource(encode, extension),
    ]
      .filter(text => text)
      .join('/')
  }
}
