import Head from 'next/head'

interface IHeadWithMetaTagsProps {
  title: string
  metaContents: ISharingMetaProperties
}

export const HeadWithMetaTags: React.FC<IHeadWithMetaTagsProps> = ({ title, metaContents }) => (
  <Head>
    <title>{title}</title>
    <meta name="title" content={metaContents.title} />
    <meta name="description" content={metaContents.description} />
    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" key="ogtype" />
    <meta property="og:url" content={metaContents.url} key="ogurl" />
    <meta property="og:title" content={metaContents.title} key="ogtitle" />
    <meta property="og:description" content={metaContents.description} key="ogdesc" />
    <meta property="og:image" content={metaContents.image} key="ogimage" />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" key="twcard" />
    <meta property="twitter:url" content={metaContents.url} key="twurl" />
    <meta property="twitter:title" content={metaContents.title} key="twtitle" />
    <meta property="twitter:description" content={metaContents.description} key="twdesc" />
    <meta property="twitter:image" content={metaContents.image} key="twimage" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </Head>
)
