import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLOR } from 'styles/constants'

import { Span } from './span'

interface IHeader extends ISpan {
  $size?: string
  $lineHeight?: string
}

const StyledSpan: React.FC<React.PropsWithChildren<ISpan>> = styled(Span)`
  font-weight: 700;
  font-family: Merriweather;
  color: ${COLOR.GREY_LV1};
`

export const Header: React.FC<React.PropsWithChildren<IHeader>> = ({
  $size,
  $lineHeight,
  children,
  ...style
}) => (
  <StyledSpan {...style} $size={$size} $lineHeight={$lineHeight} $block>
    {children}
  </StyledSpan>
)

Header.propTypes = {
  $size: PropTypes.string,
  $lineHeight: PropTypes.string,
}

Header.defaultProps = {
  $size: '36px',
  $lineHeight: '45px',
}
