import type { ColProps as BSColProps } from 'react-bootstrap'

import { Col as BSCol } from 'react-bootstrap'
import styled from 'styled-components'

import { elementStyle } from 'styles/element-style'

export interface IColProps extends BSColProps, IElement {}

const StyledCol = styled(BSCol)`
  ${elementStyle}
`

export const Col: React.FC<IColProps> = (props: IColProps) => <StyledCol {...props} />

export default Col
