import Button from '@rentspree/component-v2/dist/button'
import styled from 'styled-components'

import { COLOR } from 'styles/constants'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 72px);
  font-family: 'Source Sans Pro';
  color: ${COLOR.GREY_LV1};

  @media (max-width: 499px) {
    min-height: calc(100vh - 57px);
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  text-align: center;

  margin-bottom: 10px;
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  margin-bottom: 30px;
`

export const Wrapper = styled.div`
  width: 350px;
`

export const StyledButton = styled(Button)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${COLOR.GREY_LV1};

  text-decoration: none;
  cursor: pointer;
`

export const ImageIcon = styled.img`
  display: block;
  margin: 0 auto 20px auto;
`
