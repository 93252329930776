import { GCS_PROTOCOL, GOOGLE_CLOUD_STORAGE_HOST } from 'constant'
import env from 'env'

import { ImageProxy } from './image-proxy'

export const resizePhoto = (imageProxyHost: string, photo: string): string => {
  const imageProxy = new ImageProxy(imageProxyHost)
  imageProxy.size({ width: env.IMAGE_PROXY_RESIZE_WIDTH, height: env.IMAGE_PROXY_RESIZE_HEIGHT })
  return imageProxy.generateUrl(photo, true)
}

export const parsePhotoGcsProtocol = (url: string): string => {
  if (url.includes(GOOGLE_CLOUD_STORAGE_HOST)) {
    return url.replace(`${GOOGLE_CLOUD_STORAGE_HOST}`, GCS_PROTOCOL)
  }
  return url.replace('/', GCS_PROTOCOL)
}
