import styled from 'styled-components'

import { textStyle } from 'styles/text-style'

export const Span = styled.span`
  ${textStyle}
  display: ${(props: ISpan) => (props.$block ? 'block' : 'inline')};
  ${(props: ISpan) => props.$pointer && 'cursor: pointer;'}
  ${(props: ISpan) =>
    props.$truncate &&
    `
  display: -webkit-box;
  -webkit-line-clamp: ${props.$truncate};
  -webkit-box-orient: vertical;
  overflow: hidden;
  `}
`
