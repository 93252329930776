import { Span } from './span'

interface IBody extends ISpan {
  className?: string
  as?: string
}

export const Body: React.FC<React.PropsWithChildren<IBody>> = ({ children, ...props }) => {
  const { as, ...otherProps } = props
  return (
    <Span $textStyle="body" {...(as ? props : otherProps)}>
      {children}
    </Span>
  )
}
