import type { IContactAgentMessageModalProps } from './types'

import { BaseModal, ImageIcon, Text } from 'components'
import tracker, { events } from 'tracker'

import { CallAgentCTA } from '../buttons/call-cta'
import { ContactAgentCTA } from '../buttons/contact-cta'

import { ContactAgentMessageForm } from './form'
import { ContactAgentMessageModalContent, StickyButtonContainer } from './style'

export const ContactAgentMessageModal: React.FC<IContactAgentMessageModalProps> = props => {
  const { agentFullName, isMobile, agentPhone, isShow, onClose, onOpen } = props
  const buttonText = isMobile ? 'Message' : `Contact ${agentFullName}`
  return (
    <>
      {!isShow && (
        <StickyButtonContainer>
          <CallAgentCTA
            id="call-agent-button"
            $hideMobile={false}
            onClick={() => {
              if (agentPhone) {
                tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_CALL_TO_AGENT, {
                  state: 'loggedin',
                })
                window.location.href = `tel:${agentPhone}`
              }
            }}
            disabled={!agentPhone}
          >
            <ImageIcon
              src={agentPhone ? '../profile/phone-ring.svg' : '../profile/phone-ring-disabled.svg'}
            />
            <Text $size="14px" $bold>
              Call
            </Text>
          </CallAgentCTA>
          <ContactAgentCTA id="contact-agent-message-mobile-button" onClick={onOpen} $hideDesktop>
            {isMobile && <ImageIcon src="../profile/message.svg" />}
            <Text $size="14px" $bold>
              {buttonText}
            </Text>
          </ContactAgentCTA>
        </StickyButtonContainer>
      )}
      <BaseModal show={isShow} onClose={onClose} noContentPadding hideDesktop>
        <ContactAgentMessageModalContent>
          <ContactAgentMessageForm {...props} />
        </ContactAgentMessageModalContent>
      </BaseModal>
    </>
  )
}
