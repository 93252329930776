import type { InputElementInterface } from './types'

import { useState } from 'react'
import styled from 'styled-components'

import { COLOR } from '../../../../styles/constants'

import { baseInputCSS } from './base-input-css'

export type SelectInputOption = {
  label: string
  value: string | number | undefined
}

export type SelectInputProps = {
  name: string
  id: string
  options: SelectInputOption[]
  placeholder?: string
  defaultValue?: string | number
  onChange?: (value: string) => void
  onBlur?: () => void
}

export const SelectStyled = styled.select<InputElementInterface>`
  ${baseInputCSS}

  width: 100%;
  ${(props: InputElementInterface) => `color: ${props.color ?? 'inherit'};`}

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('/profile/chevron-down-outline.svg');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: center;
  ${(props: InputElementInterface) => `background-color: ${props.disabled ? COLOR.GREY_LV6 : ''};`}
`

export const SelectInput: React.FC<InputElementInterface & SelectInputProps> = ({
  options,
  placeholder,
  onChange,
  onBlur,
  ...props
}) => {
  const defaultColor = props.disabled ? COLOR.GREY_LV6 : COLOR.GREY_LV3
  const [currentColor, setCurrentColor] = useState(defaultColor)

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      setCurrentColor(e.target.value ? COLOR.BLACK : defaultColor)
      onChange(e.target.value)
    }
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur()
    }
  }

  return (
    <SelectStyled
      {...props}
      data-testid={`select-${props.id}`}
      onChange={handleChange}
      onBlur={handleBlur}
      color={currentColor}
    >
      {placeholder && (
        <option value="" disabled selected hidden data-testid={`${props.id}-default`}>
          {placeholder}
        </option>
      )}
      {options &&
        options.map((option: SelectInputOption) => (
          <option
            key={option.value}
            value={option.value}
            data-testid={`${props.id}-${option.value ?? 'default'}`}
          >
            {option.label}
          </option>
        ))}
    </SelectStyled>
  )
}

SelectInput.defaultProps = {
  name: '',
  id: '',
  options: [],
  placeholder: '',
  defaultValue: 0,
  onChange: (value: string) => value,
  onBlur: () => 0,
}
