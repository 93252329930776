import type { SweetAlertOptions } from 'sweetalert2'

import Swal from 'sweetalert2'

export { RentSpreeSweetalertGlobalStyle } from './sweetalert-global-style'

export const RentSpeeSweetAlertOption: SweetAlertOptions = {
  reverseButtons: true,
  buttonsStyling: false,
  customClass: {
    cancelButton: 'rsp-swal-btn rsp-swal-cancel-btn',
    confirmButton: 'rsp-swal-btn rsp-swal-confirm-btn',
    denyButton: 'rsp-swal-btn rsp-swal-deny-btn',
    container: 'rsp-swal-container',
  },
}

export const makeShowSweetAlert =
  (sweetAlertOptions: SweetAlertOptions) =>
  (
    options: SweetAlertOptions,
    afterConfirmed?: () => void,
    afterDenied?: () => void,
    afterDismissed?: (dismiss?: Swal.DismissReason) => void,
  ): void => {
    const createdMixin = Swal.mixin(sweetAlertOptions)
    // eslint-disable-next-line no-void
    void createdMixin.fire(options).then(result => {
      if (result.isConfirmed) {
        afterConfirmed?.()
      } else if (result.isDenied) {
        afterDenied?.()
      } else {
        afterDismissed?.(result.dismiss)
      }
    })
  }

export const showSweetAlert = makeShowSweetAlert(RentSpeeSweetAlertOption)
