import styled from 'styled-components'

import { GradientButton } from 'components'

interface IContactAgentCTA extends IGradientButton {
  $hideMobile?: boolean
  $hideDesktop?: boolean
}

export const ContactAgentCTA = styled(GradientButton).attrs({
  btnSize: 'lg',
  mBtnSize: 'md',
})<IContactAgentCTA>`
  display: ${({ $hideDesktop }) => ($hideDesktop ? 'none' : 'block')};
  margin-top: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  @media only screen and (max-width: 991px) {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 24px;
    display: ${({ $hideMobile }) => ($hideMobile ? 'none' : 'flex')};
    width: 35.2%;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);
    z-index: 1024;
  }
`
