import { isEmpty, pickBy } from 'lodash'

import { Col, Li, ShowMoreText, Div, H3, Hr, Body } from 'components'
import { useSelector } from 'store'
import { selectAgentSpecialty } from 'store/agent-profile'
import { flexWrap } from 'styles/utils'

export interface IAgentSpecialty {
  specialties?: {
    renterAgent: boolean
    listingAgent: boolean
    buyerAgent: boolean
    foreclosure: boolean
    shortSale: boolean
    consulting: boolean
    relocation: boolean
    commercialRealEstate: boolean
    other: string
  }
  serviceAreas?: [string]
  languages?: [string]
}

const TITLE = 'Expertise'

const SPECIALTIES_LABEL: { [k in keyof IAgentSpecialty['specialties']]: string } = {
  renterAgent: 'Rental Agent',
  listingAgent: 'Listing Agent',
  // eslint-disable-next-line quotes
  buyerAgent: "Buyer's Agent",
  foreclosure: 'Foreclosure',
  shortSale: 'Short-Sale',
  consulting: 'Consulting',
  relocation: 'Relocation',
  commercialRealEstate: 'Commercial R.E.',
}

interface ISpecialty {
  onShowMoreTextReady?: (v: number) => void
}

export const Specialty: React.FC<ISpecialty> = ({ onShowMoreTextReady }) => {
  const agentSpecialty = useSelector(selectAgentSpecialty)
  const { specialties = {}, serviceAreas = [], languages = [] } = agentSpecialty

  const agentSpecialties = pickBy(specialties, Boolean)
  const hasSpecialty = !isEmpty(agentSpecialties)
  const hasServiceAre = !isEmpty(serviceAreas)
  const hasLanguage = !isEmpty(languages)
  if (!hasSpecialty && !hasServiceAre && !hasLanguage) return null
  return (
    <Div $mMargin="0 0 24px">
      <Hr />
      <Div $mt="32" $mMargin="24px 0 0">
        <H3>{TITLE}</H3>
      </Div>
      <ShowMoreText
        line={9}
        lineOffset={8}
        mLine={13}
        mLineOffset={11}
        $mt="24"
        $mMargin="0"
        onReady={onShowMoreTextReady}
      >
        <Div className={flexWrap()}>
          {hasSpecialty && (
            <Col $width="224px" xs="12" $mPadding="0" $mMargin="16px 0 0 0">
              <Body $bold>Agent Specialties</Body>
              <Div className={flexWrap()} $mt="4">
                <Col as="ul" $pl="30" $mb="0">
                  {Object.keys(agentSpecialties).map((specialty: string) => (
                    <Li $pl="8" key={specialty}>
                      <Body>
                        {specialty === 'other'
                          ? agentSpecialties[specialty]
                          : SPECIALTIES_LABEL[specialty]}
                      </Body>
                    </Li>
                  ))}
                </Col>
              </Div>
            </Col>
          )}
          {hasServiceAre && (
            <Col $width="224px" xs="12" $mPadding="0" $mMargin="16px 0 0 0">
              <Body $bold>Service Areas</Body>
              <Div className={flexWrap()} $mt="4">
                <Col as="ul" $pl="30" $mb="0">
                  {serviceAreas.map((serviceArea: string) => (
                    <Li key={serviceArea}>
                      <Body>{serviceArea}</Body>
                    </Li>
                  ))}
                </Col>
              </Div>
            </Col>
          )}
          {hasLanguage && (
            <Col $width="151px" xs="12" lg="4" $mPadding="0" $mMargin="16px 0 0 0">
              <Body $bold>Language</Body>
              <Div className={flexWrap()} $mt="4">
                <Col as="ul" $pl="30" $mb="0">
                  {languages.map((language: string) => (
                    <Li key={language}>
                      <Body>{language}</Body>
                    </Li>
                  ))}
                </Col>
              </Div>
            </Col>
          )}
        </Div>
      </ShowMoreText>
    </Div>
  )
}
