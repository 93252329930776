import { css } from 'styled-components'

export const elementStyle = css<IElement>`
  ${props => props.$opacity && `opacity: ${props.$opacity}`};
  ${props => props.$leftSpace && `margin-left: ${props.$leftSpace}`};
  ${props => props.$rightSpace && `margin-right: ${props.$rightSpace}`};
  ${props => props.$margin && `margin: ${props.$margin}`};
  ${props => props.$mt && `margin-top: ${props.$mt}px`};
  ${props => props.$mb && `margin-bottom: ${props.$mb}px`};
  ${props => props.$mr && `margin-right: ${props.$mr}px`};
  ${props => props.$ml && `margin-left: ${props.$ml}px`};
  ${props => props.$m0 && 'margin: 0px'};
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth}`};
  ${props => props.$padding && `padding: ${props.$padding}`};
  ${props => props.$pt && `padding-top: ${props.$pt}px`};
  ${props => props.$pb && `padding-bottom: ${props.$pb}px`};
  ${props => props.$pr && `padding-right: ${props.$pr}px`};
  ${props => props.$pl && `padding-left: ${props.$pl}px`};
  ${props => props.$hideDesktop && 'display: none'};
  ${props => props.$display && `display:${props.$display}`};
  ${props => props.$width && `width: ${props.$width}`};
  ${props => props.$height && `height: ${props.$height}`};
  ${props => props.$position && `position: ${props.$position}`};
  ${props => props.$textAlign && `text-align: ${props.$textAlign}`};

  @media (max-width: 991px) {
    ${props => props.$display && `display:${props.$display}`}
    ${props => props.$mMargin && `margin: ${props.$mMargin}`};
    ${props => props.$mPadding && `padding: ${props.$mPadding}`};
    ${props => props.$hideDesktop && 'display: initial'};
    ${props => props.$hideMobile && 'display: none'};
    ${props => props.$mWidth && `width: ${props.$mWidth}`};
    ${props => props.$mHeight && `height: ${props.$mHeight}`};
    ${props => props.$mPosition && `position: ${props.$mPosition}`};
    ${props => props.$mTextAlign && `text-align: ${props.$mTextAlign}`};
  }
`

/* NOTE: Defined all props for generate elementPropKeys */
const elementProps: Required<IElement> = {
  $display: '',
  $height: '',
  $hideDesktop: false,
  $hideMobile: false,
  $leftSpace: '',
  $m0: false,
  $margin: '',
  $maxWidth: '',
  $mb: '',
  $mHeight: '',
  $ml: '',
  $mMargin: '',
  $mPadding: '',
  $mPosition: '',
  $mr: '',
  $mt: '',
  $mWidth: '',
  $opacity: '',
  $padding: '',
  $pb: '',
  $pl: '',
  $position: '',
  $pr: '',
  $pt: '',
  $rightSpace: '',
  $width: '',
  $textAlign: '',
  $mTextAlign: '',
}

export const elementPropKeys = Object.keys(elementProps)
