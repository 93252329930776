import type { IContactAgentMessageProps } from './types'

import { useEffect, useState } from 'react'
import { useMedia } from 'react-use'

import { Hr } from 'components'
import {
  RentSpreeSweetalertGlobalStyle,
  makeShowSweetAlert,
} from 'components/common/organisms/sweetalert'
import env from 'env'
import { useDispatch, useSelector } from 'store'
import { selectAgentProfileEmail, selectAgentProfileOwnerId } from 'store/agent-profile'
import { selectMessagingStatus, actions as messagingActions } from 'store/messaging'
import { actions as toastActions } from 'store/toast'
import { selectUserProfile, selectUserProfileUserType } from 'store/users'
import tracker, { events } from 'tracker'

import { GO_TO_MESSAGES_MODAL_OPTIONS } from './constants'
import { ContactAgentMessageForm } from './form'
import { ContactAgentMessageModal } from './modal'
import { ContactAgentMessageContainer } from './style'

const goToMessagesModal = makeShowSweetAlert(GO_TO_MESSAGES_MODAL_OPTIONS)

const SweetAlertGlobalStyle = RentSpreeSweetalertGlobalStyle as unknown as React.FC

export const ContactAgentMessageBox: React.FC<IContactAgentMessageProps> = ({
  agentFullName,
  agentPhone,
}) => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState<string>('')
  const [isShowModal, setIsShowModal] = useState(false)

  const agentEmail = useSelector(selectAgentProfileEmail)
  const sendMessageApiStatus = useSelector(selectMessagingStatus)
  const userType = useSelector(selectUserProfileUserType)
  const userProfile = useSelector(selectUserProfile)
  const agentProfileOwnerId = useSelector(selectAgentProfileOwnerId)
  const isMobile = useMedia('(max-width: 991px)')
  useEffect(() => {
    switch (sendMessageApiStatus) {
      case 'fulfilled':
        setMessage('')
        setIsShowModal(false)
        // eslint-disable-next-line no-void
        void goToMessagesModal(
          {
            title: 'Message sent!',
            icon: 'success',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Go to Messages',
            cancelButtonText: 'Skip for Now',
          },
          () => {
            if (window !== undefined) {
              let url = ''
              switch (userType) {
                case 'landlord':
                  url = `${env.BASE_URL}/dashboard/v2/messaging`
                  break
                case 'renter':
                  url = `${env.BASE_URL}/dashboard/v2/public/messaging`
                  break
                default:
                  break
              }
              tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_GO_TO_MESSAGE, {
                state: 'loggedin',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                button_location: 'AP profile page',
              })
              window.location.assign(url)
            }
          },
        )
        break
      case 'rejected':
        setIsShowModal(false)
        dispatch(
          toastActions.addToast({
            status: 'error',
            titleMessage: 'Something went wrong while sending message. Please try again.',
            bodyMessage: '',
          }),
        )
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageApiStatus])

  const handleMessageChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    setMessage(e.target.value)
  }

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (agentEmail && message) {
      tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_SEND_MESSAGE, {
        state: 'loggedin',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        button_location: 'AP public page',
      })
      // eslint-disable-next-line no-void
      void dispatch(
        messagingActions.sendDirectMessage({
          recipient: agentEmail,
          agentProfileOwnerId: agentProfileOwnerId || '',
          message,
          sender: userProfile,
          source: 'agent-profile',
        }),
      )
    }
  }

  return (
    <>
      <SweetAlertGlobalStyle />
      <Hr $mt="32" $hideMobile />
      <ContactAgentMessageContainer $mt="32" $padding="24px 20px" $hideMobile>
        <ContactAgentMessageForm
          message={message}
          onMessageChange={handleMessageChange}
          agentFullName={agentFullName}
          onSubmit={handleSubmit}
          isLoading={sendMessageApiStatus === 'pending'}
        />
      </ContactAgentMessageContainer>
      <ContactAgentMessageModal
        isShow={isShowModal}
        onClose={() => {
          setIsShowModal(false)
        }}
        onOpen={() => {
          setIsShowModal(true)
        }}
        message={message}
        onMessageChange={handleMessageChange}
        agentFullName={agentFullName}
        agentPhone={agentPhone}
        onSubmit={handleSubmit}
        isLoading={sendMessageApiStatus === 'pending'}
        isMobile={isMobile}
      />
    </>
  )
}
