/* eslint-disable @typescript-eslint/naming-convention */
type Event = {
  rentspree_agent_profile_public_page: {
    agent_review_page: {
      content_resized: {
        width: number
        height: number
      }
      close_agent_review_modal: never
      fetch_review_success: never
      submit_review_success: never
    }
  }
}

type EventMsg<T> = { domain: string; context: string; eventName: string; payload?: T }

export const sendEvent = <T>(msg: EventMsg<T>): void => {
  window.parent.postMessage(msg, '*')
}

export const buildEvent = <
  D extends keyof Event,
  C extends keyof Event[D],
  E extends keyof Event[D][C],
>(event: {
  domain: D
  context: C
  eventName: E
  payload?: Event[D][C][E]
}): {
  domain: D
  context: C
  eventName: E
  payload?: Event[D][C][E]
} => event
