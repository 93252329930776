import PropTypes from 'prop-types'

import { StyledButton, ButtonIcon } from '../styled-button'

type IProcessingButton = IStyledButton & { text?: string | undefined }

export const ProcessingButton: React.FC<IProcessingButton> = ({
  text,
  children,
  gradientHovered,
  ...props
}) => (
  <StyledButton {...props} as="button" disabled gradientHovered={gradientHovered}>
    <ButtonIcon
      className="fas fa-circle-notch fa-spin"
      mr={!!children}
      size={children ? '12px' : '18px'}
    />
    {children}
  </StyledButton>
)

ProcessingButton.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  gradientHovered: PropTypes.oneOf(['blue', 'red', 'yellow', 'secondary']),
}

ProcessingButton.defaultProps = {
  text: undefined,
  children: undefined,
  gradientHovered: undefined,
}

export default ProcessingButton
