/* eslint-disable jsx-a11y/anchor-is-valid */
import { TotalReviews } from '@rentspree/component-v3.components.atoms.total-reviews'
import { NumberWithStarRating } from '@rentspree/component-v3.components.molecules.number-with-star-rating'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { RichText, ShowMoreText, Div, H3, ImageIcon } from 'components/common'
import { Subtitle1 } from 'components/common/atoms/typography/subtitle'
import { useSelector } from 'store'
import { selectAgentContactInfo, selectAgentProfileAboutMe } from 'store/agent-profile'
import { selectAgentReviewSummaryForAboutMeSection } from 'store/agent-review-summary'

import { ItemWrapper } from '../contact-info/contact-info-old'

import { AboutMeTitleText } from './about-me-title-text'

interface IAboutMe {
  onShowMoreTextReady?: (v: number) => void
}

export const AboutMe: React.FC<IAboutMe> = ({ onShowMoreTextReady }) => {
  const router = useRouter()

  const agentProfileAboutMe = useSelector(selectAgentProfileAboutMe)
  const { phone, email } = useSelector(selectAgentContactInfo)
  const { percentage, totalReviews } = useSelector(selectAgentReviewSummaryForAboutMeSection)

  return (
    <Div $width="616px" $mWidth="100%" $mb="32" $mMargin="4px 0 24px">
      <Div $mt="25" $mMargin="0">
        <AboutMeTitleText />
      </Div>
      {agentProfileAboutMe.headline && (
        <Div $mt="8">
          <H3>{agentProfileAboutMe.headline}</H3>
        </Div>
      )}

      {totalReviews > 0 && (
        <ItemWrapper $center $gap="10px" $mt="10" $mMargin="8px 0 0 0">
          <NumberWithStarRating percentage={percentage} size="sm" isUIKitV4 />
          <Link href={`${router.basePath}#review`}>
            <TotalReviews count={totalReviews} textTemplate="subtitle-14" color="secondary" />
          </Link>
        </ItemWrapper>
      )}

      {phone && (
        <ItemWrapper $center $hideDesktop $mMargin="16px 0 0 0">
          <Div $height="32px" $alignSelf="center">
            <ImageIcon id="phone" src="../profile/phone.svg" $width="32px" $height="32px" />
          </Div>
          <Subtitle1 className="text-break" $ml="10">
            {phone}
          </Subtitle1>
        </ItemWrapper>
      )}

      <ItemWrapper $center $hideDesktop $mMargin={phone ? '"4px 0 0 0"' : '16px 0 0 0'}>
        <Div $height="32px" $alignSelf="center">
          <ImageIcon src="../profile/email-contact-info.svg" $width="32px" $height="32px" />
        </Div>
        <Subtitle1 className="text-break" $ml="10">
          {email}
        </Subtitle1>
      </ItemWrapper>
      <Div $mt="24">
        <H3>About Me</H3>
      </Div>
      {agentProfileAboutMe.aboutMe && (
        <ShowMoreText
          $margin="24px 0 0 0"
          $mMargin="16px 0 0 0"
          line={8}
          lineOffset={6}
          onReady={onShowMoreTextReady}
        >
          <RichText $textStyle="body">{agentProfileAboutMe.aboutMe}</RichText>
        </ShowMoreText>
      )}
    </Div>
  )
}
