import styled from 'styled-components'

import { textStyle } from 'styles/text-style'

const StyledH1 = styled.h1`
  ${textStyle}
`

export const H1: React.FC<React.PropsWithChildren<ISpan>> = ({ children, ...props }) => (
  <StyledH1 $textStyle="h1" {...props}>
    {children}
  </StyledH1>
)
