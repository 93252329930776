import { ToastFloater } from 'components/common/molecules'
import { useDispatch, useSelector } from 'store'
import { actions, selectToast } from 'store/toast'

export const Toast: React.FC = () => {
  const { toasts } = useSelector(selectToast)
  const dispatch = useDispatch()
  return (
    <ToastFloater
      toasts={toasts}
      onClose={id => dispatch(actions.closeToast(id as string))}
      timeOut={3000}
      width={400}
    />
  )
}
