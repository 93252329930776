import Panel from '@rentspree/component-v2/dist/panel'
import { useEffect } from 'react'
import styled from 'styled-components'

interface IStyledToastProps {
  $timeOut: number
}

const StyledToast = styled(Panel)<IStyledToastProps>`
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
  font-family: Source Sans Pro;

  ${({ $timeOut }) => `
    animation: fade ${$timeOut / 1000}s linear forwards;
    transition: all ${$timeOut / 1000}s ease-out;
    `}

  @keyframes fade {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }

    10% {
      opacity: 1;
      transform: translateY(0%);
    }

    90% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
`
type OnClose = () => void

export type ToastProps = {
  status: string
  titleMessage: string
  bodyMessage: string
  timeOut?: number
  onClose: OnClose
}

export const Toast: React.FC<ToastProps> = ({
  status,
  titleMessage,
  bodyMessage,
  timeOut = 3000,
  onClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(onClose, timeOut)
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledToast
      $timeOut={timeOut}
      status={status}
      titleMessage={titleMessage}
      bodyMessage={bodyMessage}
      onClose={onClose}
    />
  )
}
