import { css } from 'styled-components'

import { selectDesktopTypographyStyle, selectMobileTypographyStyle } from './css'
import { elementStyle } from './element-style'

export const textStyle = css<ISpan>`
  ${elementStyle}
  ${props => props.$textStyle && selectDesktopTypographyStyle(props.$textStyle)}
  color: ${props => (props.$color ? props.$color : 'inherit')};
  ${props => props.$bold && 'font-weight: 700'};
  ${props => props.$semiBold && 'font-weight: 600'};
  ${props => props.$regular && 'font-weight: 400'};
  ${props => props.$light && 'font-weight: 300'};
  ${props => props.$weight && `font-weight: ${props.$weight}`};
  ${props => props.$fontStyle && `font-style: ${props.$fontStyle}`};
  ${props => props.$size && `font-size: ${props.$size}`};
  ${props => props.$lineHeight && `line-height: ${props.$lineHeight}`};
  ${props => props.$align && `text-align: ${props.$align}`};
  ${props => props.$lineThrough && 'text-decoration: line-through'};
  ${props => props.$underline && 'text-decoration: underline'};
  ${props => props.$decoration && `text-decoration: ${props.$decoration}`};
  ${props => props.$center && 'text-align: center'};
  ${props => props.$letterSpacing && `letter-spacing: ${props.$letterSpacing}px`};

  @media (max-width: 991px) {
    ${props =>
      (props.$mTextStyle || props.$textStyle) &&
      selectMobileTypographyStyle(props.$mTextStyle ?? props.$textStyle)}
    ${props => props.$mColor && `color: ${props.$mColor}`};
    ${props => props.$mSize && `font-size: ${props.$mSize}`};
    ${props => props.$mWeight && `font-weight: ${props.$mWeight}`};
    ${props => props.$mLineHeight && `line-height: ${props.$mLineHeight}`};
    ${props => props.$mCenter && 'text-align: center'};
  }
`
