import styled from 'styled-components'

import { textStyle } from 'styles/text-style'

type Props = React.PropsWithChildren<
  ISpan & {
    id?: string
  }
>

export const Li: React.FC<Props> = styled.li<Props>`
  ${textStyle}
`
