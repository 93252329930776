import { object, string } from 'yup'

export const contactAgentEmailModalFormValidation = object({
  fullName: string()
    .min(1)
    .max(50, 'Must not exceed 50 characters')
    .required('Please add your name for an agent to contact you.'),
  email: string()
    .email('Invalid email format')
    .required('Please add your email for an agent to contact you.'),
  phone: string().min(10, 'Phone Number must be at least 10 characters').optional(),
  message: string().optional(),
})
