import styled from 'styled-components'

import { FLEX_CENTER, COLOR } from 'styles/constants'

export const SectionBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50px;
  position: sticky;
  top: 70px;
  z-index: 5;
  background-color: ${COLOR.GREY_LV6};
  box-shadow: 0px 1px 1px ${COLOR.GREY_LV4};
`

const Content = styled.div`
  ${FLEX_CENTER}
  flex-direction: row;
  width: 920px;
  justify-content: space-between;

  @media (max-width: 991px) {
    width: 100%;
    padding: 15px 20px;
  }
`
export const ActionBar: React.FC<React.PropsWithChildren> = ({ children }) => (
  <SectionBlock>
    <Content>{children}</Content>
  </SectionBlock>
)
