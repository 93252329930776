import { useSelector } from 'react-redux'

import { useTracker } from '@hooks'
import { ContactRentSpree, FooterTemplate, ListWithHeader } from 'components'
import { socialContacts, SUPPORT_EMAIL, SUPPORT_PHONE } from 'constant'
import env from 'env'
import { selectUserId } from 'store/users'
import { COLOR } from 'styles/constants'
import { FOOTER } from 'tracker/events'

// eslint-disable-next-line
const internalLink = (uri: string): string => `${env.BASE_URL}${uri}`

export const companyInfoLinks = [
  { displayText: 'About Us', link: internalLink('/about') },
  { displayText: 'Careers', link: internalLink('/careers') },
  { displayText: 'Press', link: internalLink('/category/rentspree-news') },
  { displayText: 'Contact Us', link: internalLink('/contact-us') },
]

export const whatWeServeLinks = [
  { displayText: 'Real Estate Agents', link: internalLink('/agent') },
  { displayText: 'Landlords', link: internalLink('/landlord-owner') },
  { displayText: 'Property Managers', link: internalLink('/property-manager') },
  { displayText: 'Renters', link: internalLink('/renter') },
]
type FeatureLink = Record<'displayText' | 'link', string>
export const featureLinks: Array<FeatureLink> = [
  { displayText: 'Tenant Screening', link: internalLink('/tenant-screening') },
  { displayText: 'Rental Application', link: internalLink('/rental-application') },
  { displayText: 'Accept & Deny', link: internalLink('/adverse-action-letter') },
  { displayText: 'Rent Estimate', link: internalLink('/rent-estimate') },
  { displayText: 'E-Sign Document', link: internalLink('/electronic-signature') },
  { displayText: 'Renters Insurance', link: internalLink('/renters-insurance') },
  { displayText: 'Rent Payment', link: internalLink('/rent-payment') },
  { displayText: 'Document Upload', link: internalLink('/document-upload') },
  { displayText: 'Auto-Reference Check', link: internalLink('/reference-checks') },
  { displayText: 'Agent Profile', link: internalLink('/agent-profile') },
  { displayText: 'Listing Pages', link: internalLink('/listing-pages') },
  { displayText: 'Client Manager', link: internalLink('/rental-client-manager') },
]

const removedFeatureLinks = ['Rent Payment', 'Renters Insurance']

export const loggedInFeatureLinks = featureLinks.filter(
  (element: FeatureLink) => !removedFeatureLinks.includes(element.displayText),
)

export const Footer: React.FC = () => {
  const track = useTracker()
  const withLeavePageViaFooterTracker = (link: IFooterLink): IFooterLink => {
    const modifiedOnClick = (): void => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      track(FOOTER.LEAVE_PAGE_VIA_FOOTER, { click_on: link.displayText })
      if (link.onClick) {
        link.onClick()
      }
    }
    return { ...link, onClick: modifiedOnClick }
  }

  const userId = useSelector(selectUserId)
  const listStyle = {
    headerColor: COLOR.MINT_GREEN_LV3,
    headerStyle: { $margin: '0 0 16px 0' },
    linkColor: 'white',
    linkHoverColor: 'white',
    spacingBetweenContent: '0',
  }

  return (
    <FooterTemplate>
      <ListWithHeader
        headerText="RentSpree"
        {...listStyle}
        listItem={companyInfoLinks.map(withLeavePageViaFooterTracker)}
        divStyle={{ $width: '181px' }}
      />
      {!userId && (
        <ListWithHeader
          headerText="Who We Serve"
          {...listStyle}
          listItem={whatWeServeLinks.map(withLeavePageViaFooterTracker)}
          divStyle={{ $width: '229px' }}
        />
      )}
      <ListWithHeader
        headerText="Features"
        {...listStyle}
        listItem={
          userId
            ? loggedInFeatureLinks.map(withLeavePageViaFooterTracker)
            : featureLinks.map(withLeavePageViaFooterTracker)
        }
        divStyle={{ $width: '295px' }}
      />
      <ContactRentSpree
        weekdayTime="Mon-Fri: 7AM-5PM PT"
        weekendTime="Sat-Sun: 8AM-Noon PT"
        socialContacts={socialContacts}
        phone={SUPPORT_PHONE}
        email={SUPPORT_EMAIL}
        $width="359px"
      />
    </FooterTemplate>
  )
}
