/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from '@mui/material'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'

import { RichText, ShowMoreText, H3 } from 'components/common'
import { useSelector } from 'store'
import { selectAgentProfileAboutMe } from 'store/agent-profile'

interface IAboutMe {
  onShowMoreTextReady?: (v: number) => void
}

export const AboutMe: React.FC<IAboutMe> = ({ onShowMoreTextReady }) => {
  const agentProfileAboutMe = useSelector(selectAgentProfileAboutMe)

  return (
    <Box marginBottom="24px">
      <Card variant="outlined">
        <CardElement>
          {agentProfileAboutMe.headline && (
            <Box>
              <H3>{agentProfileAboutMe.headline}</H3>
            </Box>
          )}
          {agentProfileAboutMe.aboutMe && (
            <ShowMoreText
              $margin="24px 0 0 0"
              $mMargin="16px 0 0 0"
              line={8}
              lineOffset={6}
              onReady={onShowMoreTextReady}
            >
              <RichText $textStyle="body">{agentProfileAboutMe.aboutMe}</RichText>
            </ShowMoreText>
          )}
        </CardElement>
      </Card>
    </Box>
  )
}
