import { H2, Hr } from 'components'
import { COLOR } from 'styles/constants'

import { Container } from '../../layouts'

const TITLE_UNIT = 'px'
interface ISection extends IElement {
  title?: string
  titleLineHeight?: number
  titleSize?: number
  $mWidth?: string
  $mt?: number
  $padding?: string
  $mPadding?: string
  $mMargin?: string
}

export const Section: React.FC<React.PropsWithChildren<ISection>> = ({
  title,
  titleLineHeight,
  titleSize,
  children,
  $mWidth,
  $mt,
  $padding,
  $mPadding,
  $mMargin,
  ...props
}) => (
  <Container
    $mWidth={$mWidth}
    $mt={$mt}
    $padding={$padding}
    $mPadding={$mPadding}
    $mMargin={$mMargin}
    {...props}
  >
    <Hr $opacity="1" $mMargin="20px 0" />
    {title && (
      <H2
        $size={`${titleSize ?? '0'}${TITLE_UNIT}`}
        $lineHeight={`${titleLineHeight ?? '0'}${TITLE_UNIT}`}
        $weight="600"
        $mt={20}
        $mb={5}
        $color={COLOR.GREY_LV1}
      >
        {title}
      </H2>
    )}
    {children}
  </Container>
)

Section.defaultProps = {
  title: 'Section title',
  titleLineHeight: 40,
  titleSize: 22,
  $mWidth: '100%',
  $mt: 30,

  // $padding: '30px 0 0 0',
  $padding: '0',
  $mPadding: '10px 20px',
  $mMargin: '0',
}
