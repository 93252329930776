import styled from 'styled-components'

import { textStyle } from 'styles/text-style'

type IParagraph = ISpan

export const RichText = styled.p<IParagraph>`
  white-space: pre-wrap;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  overflow-wrap: break-word;
  ${textStyle};
`
