export class SourceUrl {
  imageUrl: string

  imageUrlEncode: string

  constructor(imageUrl: string) {
    this.imageUrl = imageUrl
    this.imageUrlEncode = Buffer.from(imageUrl).toString('base64')
  }

  getImageSource(encoded: boolean = false, extension?: string): string {
    if (encoded) {
      return [this.imageUrlEncode, extension].filter(text => text).join('.')
    }
    return [`plain/${this.imageUrl}`, extension].filter(text => text).join('@')
  }
}
