import { generateAddress } from '@rentspree/helper'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

import { ButtonLink, Div, ImageIcon, H3, Body } from 'components'
import { useSelector } from 'store'
import {
  selectAgentContactInfo,
  selectAgentProfileContactInformationWithAdjustUrl,
} from 'store/agent-profile'
import tracker, { events } from 'tracker'

import { ContactAgent } from './contact-agent'
import { SocialContact } from './social-contact'

interface ItemWrapperPropsInterface {
  $center?: boolean
  $hideMobile?: boolean
  $hideDesktop?: boolean
  $mMargin?: string
  $mt?: string
  $mOrder?: string
  $gap?: string
}

const Container = styled(Div)`
  width: 280px;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    ${props => props.$mMargin && `margin: ${props.$mMargin};`}
    width: 100%
  }
`

export const ItemWrapper = styled(Div)<ItemWrapperPropsInterface>`
  display: ${props => (props.$hideDesktop ? 'none' : 'flex')};
  flex-direction: row;
  ${props => props.$mt && `margin-top: ${props.$mt}px;`}
  ${props => props.$gap && `gap: ${props.$gap}`};

  ${props => props.$center && 'align-items: center;'}

  @media (max-width: 991px) {
    ${props => props.$hideMobile && 'display: none;'}
    ${props => props.$hideDesktop && 'display: flex;'}
    ${props => props.$mMargin && `margin: ${props.$mMargin};`}
    ${props => props.$mOrder && `order: ${props.$mOrder};`}
  }
`

export const ButtonLinkWebsiteWrapper = styled(Div)`
  word-break: break-all;
`

interface IContactInfo {
  isPreview?: boolean
}

export const ContactInfo: React.FC<IContactInfo> = ({ isPreview = false }) => {
  const { contactInformation, phone, email } = useSelector(selectAgentContactInfo)
  const {
    facebookUrl,
    twitterUrl,
    instagramUrl,
    linkedInUrl,
    tiktokUrl,
    primaryWebsiteUrl,
    secondaryWebsiteUrl,
  } = useSelector(selectAgentProfileContactInformationWithAdjustUrl)

  const hasWebSite =
    contactInformation?.primaryWebsiteUrl || contactInformation?.secondaryWebsiteUrl

  const hasBrokerageAddress =
    !isEmpty(contactInformation?.brokerage) &&
    Object.keys(contactInformation?.brokerage ?? {}).some(
      key => !isEmpty(contactInformation?.brokerage?.[key]),
    )

  const hasSocialContact =
    !!contactInformation?.facebookUrl ||
    !!contactInformation?.twitterUrl ||
    !!contactInformation?.instagramUrl ||
    !!contactInformation?.linkedInUrl ||
    !!contactInformation?.tiktokUrl

  return (
    <Container $mMargin="0 0 24px">
      <Div $mt="31" $mMargin="24px 0 0 0">
        <H3>Contact information</H3>
      </Div>
      {phone && (
        <ItemWrapper $center $hideMobile $mt="24">
          <Div $padding="2px 0 0" $height="20px" $alignSelf="flex-start">
            <ImageIcon src="../profile/phone.svg" $mt="-5" $width="32px" $height="32px" />
          </Div>
          <Body className="text-break" $ml="8" $semiBold>
            {phone}
          </Body>
        </ItemWrapper>
      )}

      {email && (
        <ItemWrapper $center $hideMobile $mt="16">
          <Div $padding="2px 0 0" $height="20px" $alignSelf="flex-start">
            <ImageIcon
              src="../profile/email-contact-info.svg"
              $mt="-5"
              $width="32px"
              $height="32px"
            />
          </Div>
          <Body className="text-break" $ml="8" $semiBold>
            {email}
          </Body>
        </ItemWrapper>
      )}

      {hasBrokerageAddress && (
        <ItemWrapper $mt="16">
          {/* need div here to manage word break broke the spacing */}
          <Div $pt="2.5" $mPadding="0">
            <ImageIcon src="./location.svg" $mt="-5" $width="32px" $height="32px" />
          </Div>
          <Div $ml="8">
            <Body $mTextStyle="subtitle1" $semiBold $block>
              {contactInformation?.brokerage?.name}
            </Body>
            <Body $block $mt="4">
              {generateAddress({
                street: contactInformation?.brokerage?.address,
                city: contactInformation?.brokerage?.city,
                state: contactInformation?.brokerage?.state,
                zipCode: contactInformation?.brokerage?.zipCode,
              })}
            </Body>
          </Div>
        </ItemWrapper>
      )}

      {hasWebSite && (
        <ItemWrapper $mt="16">
          <Div>
            <ImageIcon src="../profile/global.svg" $mt="-5" $width="32px" $height="32px" />
          </Div>
          <Div $ml="8" $width="calc(100% - 40px)">
            {contactInformation?.primaryWebsiteUrl && (
              <ButtonLinkWebsiteWrapper>
                <ButtonLink
                  className="text-truncate d-block"
                  $mTextStyle="subtitle1"
                  $regular
                  $block
                  text={`${contactInformation?.primaryWebsiteUrl}`}
                  onClick={() => {
                    if (!isEmpty(contactInformation?.primaryWebsiteUrl)) {
                      tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_PRIMARY_WEBSITE_LINK)
                      window.open(primaryWebsiteUrl ?? '', '_blank')
                    }
                  }}
                />
              </ButtonLinkWebsiteWrapper>
            )}
            {contactInformation?.secondaryWebsiteUrl && (
              <ButtonLinkWebsiteWrapper>
                <ButtonLink
                  className="text-truncate d-block"
                  $mTextStyle="subtitle1"
                  $regular
                  $block
                  text={`${contactInformation?.secondaryWebsiteUrl}`}
                  onClick={() => {
                    if (!isEmpty(contactInformation?.secondaryWebsiteUrl)) {
                      tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_SECONDARY_WEBSITE_LINK)
                      window.open(secondaryWebsiteUrl ?? '', '_blank')
                    }
                  }}
                />
              </ButtonLinkWebsiteWrapper>
            )}
          </Div>
        </ItemWrapper>
      )}

      {hasSocialContact && (
        <SocialContact
          contacts={{
            facebook: facebookUrl,
            twitter: twitterUrl,
            instagram: instagramUrl,
            linkedin: linkedInUrl,
            tiktok: tiktokUrl,
          }}
        />
      )}

      <ContactAgent isPreview={isPreview} />
    </Container>
  )
}
