import cx from 'classnames'
import range from 'lodash/range'
import { useEffect, useState } from 'react'

import { PaginationWrapper, Pager, PagerBtn } from './style'

type PaginationProps = {
  total: number
  page?: number
  isAllPage?: boolean
  onPageChange: (current: number) => void
}

export const Pagination: React.FC<PaginationProps> = props => {
  const { total, page = 1, isAllPage = true, onPageChange } = props
  const [pages, setPages] = useState<Array<number>>([])
  const [current, setCurrent] = useState<number>(page || 1)
  const jumpValue = 3

  useEffect(() => {
    if (current !== page) setCurrent(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const findNextCurrent = () => {
    const nextValue = total - current <= jumpValue ? total - current : jumpValue
    return current + nextValue
  }
  const findPrevCurrent = () => {
    const prevValue = current - jumpValue > 0 ? jumpValue : current + 1
    return current - prevValue
  }
  const getPage = () => {
    // case no jumper
    if (isAllPage || total <= 5) return range(1, total + 1)

    // case no prev jumper
    if (current < 4) {
      return range(1, 4)
    }

    // case no next jumper
    if (current > total - 3) {
      return range(total - 2, total + 1)
    }

    // both jumper
    return range(current, current + 1)
  }

  useEffect(() => {
    setPages(getPage())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPages(getPage())
    if (current !== page) onPageChange(current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  const onPrev = () => {
    if (current > 1) {
      setCurrent(current - 1)
    }
  }

  const onNext = () => {
    if (current < total) {
      setCurrent(current + 1)
    }
  }
  const onSelect = (selectedCurrent: number) => {
    if (selectedCurrent >= 1 && selectedCurrent <= total) {
      setCurrent(selectedCurrent)
    }
  }
  const isLeftButtonActive = current > 1
  const isRightButtonActive = current < total
  return (
    <PaginationWrapper>
      {total > 1 && (
        <Pager>
          <PagerBtn id="leftArrow" onClick={onPrev} disabled={!isLeftButtonActive}>
            <span className={`icon icon-left-arrow ${!isLeftButtonActive ? 'disabled' : ''}`} />
          </PagerBtn>
          {!isAllPage && current >= 4 && total > 5 && (
            <>
              <PagerBtn
                className={cx({ current: current === 1 })}
                key={1}
                onClick={() => onSelect(1)}
              >
                1
              </PagerBtn>
              <PagerBtn onClick={() => onSelect(findPrevCurrent())}>...</PagerBtn>
            </>
          )}
          {pages.map(p => (
            <PagerBtn
              className={cx({ current: current === p })}
              key={p}
              onClick={() => onSelect(p)}
            >
              {p}
            </PagerBtn>
          ))}
          {!isAllPage && current <= total - 3 && total > 5 && (
            <>
              <PagerBtn onClick={() => onSelect(findNextCurrent())}>...</PagerBtn>
              <PagerBtn
                className={cx({ current: current === total })}
                key={total}
                onClick={() => onSelect(total)}
              >
                {total}
              </PagerBtn>
            </>
          )}

          <PagerBtn id="rightArrow" onClick={onNext} disabled={!isRightButtonActive}>
            <span className={`icon icon-right-arrow ${!isRightButtonActive ? 'disabled' : ''}`} />
          </PagerBtn>
        </Pager>
      )}
    </PaginationWrapper>
  )
}
