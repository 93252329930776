import styled from 'styled-components'

import { Div, Img } from 'components/common/atoms'
import { COLOR, SHADOW_LV1, SHADOW_LV2 } from 'styles/constants'

export const Card = styled(Div)`
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $height }) => $height && `height: ${$height};`}
  background: ${COLOR.WHITE};
  border: 1px solid ${COLOR.GREY_LV4};
  box-sizing: border-box;
  box-shadow: ${SHADOW_LV1};
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    box-shadow: ${SHADOW_LV2};
  }
  @media (max-width: 991px) {
    ${({ $mWidth }) => $mWidth && `width: ${$mWidth};`}
    height: unset;
    ${({ $mHeight }) => $mHeight && `min-height: ${$mHeight};`}
  }
`
export const CoverImage = styled(Img)`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
`
export const TextSection = styled(Div)`
  padding: 16px;
  height: 192px;
  position: relative;
  @media (max-width: 991px) {
    height: unset;
    min-height: 124px;
    position: unset;
  }
`
export const TextBottomSection = styled(Div)`
  position: absolute;
  bottom: 8px;
  @media (max-width: 991px) {
    bottom: 0;
    position: unset;
    margin-top: 8px;
  }
`
export const StyledA = styled.a`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
