import type { IProcessingOptionsInterface } from './interfaces'

export enum ResizingType {
  FIT = 'fit',
  FILL = 'fill',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FILL_DOWN = 'fill-down',
  FORCE = 'force',
  AUTO = 'auto',
}

export type SizeOptionsType = {
  width?: number
  height?: number
  enlarge?: boolean
  extend?: boolean
}

export type ResizeOptionsType = SizeOptionsType & {
  rsType?: ResizingType
}

export type ProcessingOptions = { [key: string]: IProcessingOptionsInterface }
