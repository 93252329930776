import { useEffect, useRef, useState } from 'react'

type CustomResizeCallback = (width: number, height: number) => void

type CustomResizeObserverReturnType<E extends Element> = {
  elementRef: React.MutableRefObject<E | null>
  registerCallbackOnce: (val: CustomResizeCallback) => void
  closeListener: () => void
}

export const useCustomResizeObserver = <E extends Element>(): CustomResizeObserverReturnType<E> => {
  const elementRef = useRef<E | null>(null)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)
  const [callback, setCallback] = useState<CustomResizeCallback>()

  useEffect(() => {
    if (!elementRef.current || !callback || resizeObserverRef.current) return

    resizeObserverRef.current = new ResizeObserver(entries => {
      let elementWidth: number
      let elementHeight: number

      if (entries[0]?.borderBoxSize) {
        const { inlineSize, blockSize } = entries[0]?.borderBoxSize?.[0] ?? {}
        elementWidth = inlineSize
        elementHeight = blockSize
      } else {
        const { width, height } = entries[0]?.contentRect ?? {}
        elementWidth = width
        elementHeight = height
      }
      callback(elementWidth, elementHeight)
    })

    resizeObserverRef.current.observe(elementRef.current)
  }, [elementRef, callback])

  const registerCallbackOnce = (val: CustomResizeCallback) => {
    if (callback) return

    setCallback(() => val)
  }

  const closeListener = () => {
    if (elementRef.current) {
      resizeObserverRef.current?.unobserve(elementRef.current)
    }

    resizeObserverRef.current = null
    setCallback(undefined)
  }

  useEffect(() => closeListener, [])

  return {
    elementRef,
    registerCallbackOnce,
    closeListener,
  }
}
