import type { FlattenInterpolation } from 'styled-components'

import { css } from 'styled-components'

import { TEXT_BOLD, TEXT_REGULAR, TEXT_SEMI_BOLD } from 'styles/constants'

export const DESKTOP_H1 = css`
  font-weight: ${TEXT_BOLD};
  font-size: 44px;
  line-height: 64px;
`

export const DESKTOP_H2 = css`
  font-weight: ${TEXT_BOLD};
  font-size: 32px;
  line-height: 48px;
`

export const DESKTOP_H3 = css`
  font-weight: ${TEXT_BOLD};
  font-size: 22px;
  line-height: 32px;
`

export const DESKTOP_SUBTITLE1 = css`
  font-weight: ${TEXT_REGULAR};
  font-size: 22px;
  line-height: 32px;
`

export const DESKTOP_SUBTITLE2 = css`
  font-weight: ${TEXT_BOLD};
  font-size: 16px;
  line-height: 28px;
`

export const DESKTOP_BUTTON = css`
  font-weight: ${TEXT_SEMI_BOLD};
  font-size: 16px;
  line-height: 28px;
`

export const DESKTOP_BODY = css`
  font-size: 16px;
  line-height: 28px;
`

export const DESKTOP_BODY_SMALL = css`
  font-size: 14px;
  line-height: 24px;
`

export const DESKTOP_CAPTION = css`
  font-size: 12px;
  line-height: 16px;
`

export function selectDesktopTypographyStyle(
  textStyle?: string,
): FlattenInterpolation<unknown> | null {
  switch (textStyle) {
    case 'h1':
      return DESKTOP_H1
    case 'h2':
      return DESKTOP_H2
    case 'h3':
      return DESKTOP_H3
    case 'subtitle1':
      return DESKTOP_SUBTITLE1
    case 'subtitle2':
      return DESKTOP_SUBTITLE2
    case 'button':
      return DESKTOP_BUTTON
    case 'body':
      return DESKTOP_BODY
    case 'body-small':
      return DESKTOP_BODY_SMALL
    case 'caption':
      return DESKTOP_CAPTION
    default:
      return null
  }
}
