/* eslint-disable jsx-a11y/label-has-associated-control */
import type { Props } from './type'

import { Text } from 'components/common/atoms/typography'

import {
  Label,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  CheckboxBackground,
} from './styled'

export const Checkbox: React.FC<Props> = props => {
  const { isChecked, onCheck, id, name, isError, text, isDisabled, $width, $margin, $mMargin } =
    props

  return (
    <Label htmlFor={id} $width={$width} $margin={$margin} $mMargin={$mMargin}>
      <CheckboxContainer>
        <HiddenCheckbox
          id={id}
          name={name}
          checked={isChecked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation()
            onCheck(event.target.checked)
          }}
          disabled={isDisabled}
        />
        <StyledCheckbox isChecked={isChecked} isError={isError}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
          <CheckboxBackground isError={isError} />
        </StyledCheckbox>
      </CheckboxContainer>
      {text && (
        <Text $size="16px" $mSize="16px" $lineHeight="18px" $mLineHeight="18px">
          {text}
        </Text>
      )}
    </Label>
  )
}
