import template from 'lodash/template'

type Values = {
  [key: string]: string
}

export const interpolateMessage = (message: string, values: Values): string => {
  const compiler = template(message, {
    interpolate: /{([\s\S]+?)}/g,
  })
  return compiler(values)
}
