import styled, { css } from 'styled-components'

import { StyledButton } from 'components'
import { COLOR } from 'styles/constants'

interface ICallAgentCTA extends IGradientButton {
  $hideMobile?: boolean
  $hideDesktop?: boolean
}

const grayDisabled = css`
  background-color: ${COLOR.GREY_LV4};
  border: none;
  color: ${COLOR.GREY_LV3};
  box-shadow: 0 0 0 0;
  cursor: not-allowed;
`

export const CallAgentCTA = styled(StyledButton)<ICallAgentCTA>`
  display: none;
  color: ${COLOR.BLUE_LV2};
  border-radius: 20px;
  background-color: ${COLOR.WHITE};
  margin-top: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 34px 8px 34px;
  margin-right: 16px;
  @media only screen and (max-width: 991px) {
    border: 1px solid ${COLOR.BLUE_LV2};
    padding: 8px 34px;
    display: ${({ $hideMobile }) => ($hideMobile ? 'none' : 'flex')};
    width: 35.2%;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);
    z-index: 1024;
    ${props => props.disabled && grayDisabled};
  }
`
