import styled from 'styled-components'

import { COLOR } from 'styles/constants'
import { elementStyle } from 'styles/element-style'

export const ContactAgentMessageContainer = styled.div<IElement>`
  border-radius: 5px;
  background: ${COLOR.GREY_LV6};
  ${elementStyle}

  button {
    margin-top: 24px;
  }
`

export const ContactAgentMessageModalContent = styled.div`
  @media (max-width: 991px) {
    span:first-child:before {
      content: '';
      float: right;
      width: 12px;
      height: 26px;
    }
  }

  padding: 24px 28px;
`

export const StickyButtonContainer = styled.div`
  display: block;
  @media only screen and (max-width: 991px) {
    display: flex;
    position: fixed;
    bottom: 20px;
    width: calc(100%);
    left: 0;
    justify-content: center;
    z-index: 1024;
    align-items: end;
  }
`
