import type { RowProps as BSRowProps } from 'react-bootstrap'

import { Row as BSRow } from 'react-bootstrap'
import styled from 'styled-components'

import { elementStyle } from 'styles/element-style'

export interface IRowProps extends BSRowProps, IElement {}

const StyledRow = styled(BSRow)`
  ${elementStyle}
`

export const Row: React.FC<IRowProps> = (props: IRowProps) => <StyledRow {...props} />

export default Row
