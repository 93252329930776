import type { FlattenSimpleInterpolation } from 'styled-components'

import styled, { css } from 'styled-components'

import { COLOR } from 'styles/constants'

import * as color from './color'

const grayDisabled = css`
  background: ${COLOR.GREY_LV4};
  box-shadow: 0 0 0 0;
  cursor: not-allowed;
`

const gradientColors: Record<GradientColorsChoices, FlattenSimpleInterpolation> = {
  blue: color.blueGradient,
  yellow: color.yellowGradient,
  red: color.redGradient,
  secondary: color.secondaryGradient,
}

export const GradientBg = styled.div<IGradientBg>`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
  z-index: -1;
  ${props => (props.gradient ? gradientColors[props.gradient] : gradientColors.blue)};
  transition: all, 0.5s;
  &:hover {
    transition: all, 0.5s;
    transform: translateX(50%);
  }
  pointer-events: none;
  ${props => props.grayDisabled && grayDisabled};
`

export default GradientBg
