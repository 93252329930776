import { formatLocalPhone } from '@rentspree/helper'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'

import { Div, ImageIcon, Span, Title } from 'components/common/atoms'
import { List } from 'components/common/molecules'
import { COLOR } from 'styles/constants'

import { ContactInformationWrapper, SocialMedias, TradeMarkWrapper } from './style'

type ContactRentSpreeProps = {
  weekdayTime: string
  weekendTime: string
  phone: string
  email: string
  socialContacts: ISocialContact[]
} & IElement

type ContactInformation = {
  icon: string
  text: React.ReactNode
  href?: string
}

export const ContactRentSpree: React.FC<ContactRentSpreeProps> = ({
  weekdayTime,
  weekendTime,
  phone,
  email,
  socialContacts,
  ...props
}) => {
  const textStyle: Record<string, string> = useMemo(
    () => ({
      $weight: '400',
      $color: 'white',
      $mSize: '14px',
      $size: '16px',
    }),
    [],
  )
  const dataSource: ContactInformation[] = useMemo(
    () => [
      {
        icon: 'footer-time.svg',
        text: (
          <Div>
            <Span {...textStyle}>
              {weekdayTime}
              <br />
              {weekendTime}
            </Span>
          </Div>
        ),
      },
      {
        icon: 'footer-phone.svg',
        text: (
          <Div>
            <Span {...textStyle}>{formatLocalPhone(phone)}</Span>
          </Div>
        ),
        href: `tel:${phone}`,
      },
      {
        icon: 'footer-mail.svg',
        text: (
          <Div>
            <Span {...textStyle}>{email}</Span>
          </Div>
        ),
        href: `mailto:${email}`,
      },
    ],
    [textStyle, weekdayTime, weekendTime, phone, email],
  )

  const wrapWithLink = useCallback((element: React.ReactNode, href?: string, testId?: string) => {
    if (!href) return element
    return (
      <a href={href} data-testid={testId}>
        {element}
      </a>
    )
  }, [])

  const renderItem = ({ icon, text, href }: ContactInformation): React.ReactNode => (
    <>
      {wrapWithLink(
        <ContactInformationWrapper>
          <Div
            style={{
              width: '24px',
              height: '24px',
              background: `url(/profile/${icon}) no-repeat center center`,
            }}
          />
          {text}
        </ContactInformationWrapper>,
        href,
        `contact-link-${icon}`,
      )}
    </>
  )

  return (
    <Div {...props}>
      <Title
        $color={COLOR.MINT_GREEN_LV3}
        $size="32px"
        $mSize="22px"
        $lineHeight="48px"
        $mLineHeight="32px"
        $weight="700"
        $margin="0 0 16px 0"
      >
        Have a question?
      </Title>
      <List dataSource={dataSource} renderItem={renderItem} />
      <SocialMedias>
        {socialContacts.map(({ link, img }) => (
          <a
            href={link}
            key={img}
            data-testid={`social-link-${img}`}
            target="_blank"
            rel="noreferrer"
          >
            <ImageIcon src={`/profile/${img}`} />
          </a>
        ))}
      </SocialMedias>
      <TradeMarkWrapper $mt="32" style={{ lineHeight: '40px' }}>
        <Span {...textStyle}>&copy;RentSpree, Inc.</Span>
      </TradeMarkWrapper>
      {/* <TradeMarkWrapper style={{ lineHeight: '40px' }}>
        <Span {...textStyle}>Do Not Sell My Personal Information</Span>
      </TradeMarkWrapper> */}
    </Div>
  )
}

ContactRentSpree.propTypes = {
  weekdayTime: PropTypes.string.isRequired,
  weekendTime: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  socialContacts: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      img: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}
