import { Icon } from '@rentspree/component-v2/dist/icon'

import { Div } from 'components/common/atoms/div'

import { OverlayWrapper, ModalWrapper, CloseButton } from './style'

export interface IBaseModalPropsInterface extends IElement, React.PropsWithChildren<IElement> {
  show: boolean
  hideDesktop?: boolean
  hideMobile?: boolean
  noContentPadding?: boolean
  onClose: (event: React.MouseEvent<HTMLElement>) => void
}

export const BaseModal: React.FC<IBaseModalPropsInterface> = ({
  children,
  onClose,
  show,
  hideDesktop,
  hideMobile,
  noContentPadding,
  ...props
}) => {
  if (!show) {
    return null
  }

  return (
    <>
      <OverlayWrapper $hideDesktop={hideDesktop} $hideMobile={hideMobile} />
      <ModalWrapper $hideDesktop={hideDesktop} $hideMobile={hideMobile} {...props}>
        <CloseButton id="modal-close-btn" onClick={onClose}>
          <Icon icon="close" size="md" />
        </CloseButton>
        <Div $position="relative" $padding={noContentPadding ? '0px' : '20px'}>
          {children}
        </Div>
      </ModalWrapper>
    </>
  )
}
