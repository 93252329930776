import { Div, H3 } from 'components/common'
import { useSelector } from 'store'
import { selectAgentProfileAboutMe } from 'store/agent-review-page/selectors'

import { AgentTitleName } from './agent-title-name'

export const AgentTitle: React.FC = () => {
  const agentProfileAboutMe = useSelector(selectAgentProfileAboutMe)

  return (
    <Div $width="616px" $mWidth="100%" $mb="32" $mMargin="4px 0 24px">
      <Div $mt="25" $mMargin="0">
        <AgentTitleName />
      </Div>
      {agentProfileAboutMe.headline && (
        <Div $mt="8">
          <H3>{agentProfileAboutMe.headline}</H3>
        </Div>
      )}
    </Div>
  )
}
