import { useRouter } from 'next/router'

import { ButtonLink } from 'components'
import { useSelector } from 'store'
import { useTracker } from 'store/@hooks/use-tracker'
import { selectAgentProfileSlug } from 'store/agent-review-page'
import { AGENT_REVIEW } from 'tracker/events'

import { BUTTON_DESCRIPTION, RIGHT_ARROW } from './constant'
import { ButtonArrowWrapper, ButtonFooterWrapper, ButtonTextWrapper } from './styles'

interface IReviewFooterProps {
  setLoadingContainer: (v: boolean) => void
}

const ButtonText: React.FC = () => (
  <ButtonTextWrapper>
    {BUTTON_DESCRIPTION}
    <ButtonArrowWrapper>{RIGHT_ARROW}</ButtonArrowWrapper>
  </ButtonTextWrapper>
)

export const ReviewFooter: React.FC<IReviewFooterProps> = ({ setLoadingContainer }) => {
  const slug = useSelector(selectAgentProfileSlug)
  const router = useRouter()
  const track = useTracker()

  const handleOpenAllReviews = async () => {
    track(AGENT_REVIEW.EVENT.CLICK_VIEW_ALL_REVIEWS)
    setLoadingContainer(true)

    // TODO: fix lint broken here.
    await router.push(`/profile/${slug}#review`)
  }

  return (
    <ButtonFooterWrapper>
      <ButtonLink
        data-testid="redirect-review-button"
        $ml="12.5"
        $textStyle="body-small"
        $semiBold
        text={<ButtonText />}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleOpenAllReviews}
      />
    </ButtonFooterWrapper>
  )
}
