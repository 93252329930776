import type { ISubmitResult, SubmitResultDisplayType } from './types'

import { CloseButton, Container, ContentWrapper, ImageIcon, Title } from './styled'

type PageTypeMap = { [key in SubmitResultDisplayType]: string }

export const titleMap: PageTypeMap = {
  thankYou: 'Thank You',
  gotIt: 'Got It',
  expiredSession: 'This link has expired.',
}

export const descriptionMap: PageTypeMap = {
  thankYou: 'We submitted your rating.',
  gotIt: 'Thanks for letting us know.',
  expiredSession: '',
}

const iconPathMap: PageTypeMap = {
  thankYou: 'mail-with-paper-plane.svg',
  gotIt: 'thumbs-up.svg',
  expiredSession: 'expired-session.svg',
}

type SubmitResultContent = {
  title: string
  description: string
  iconPath: string
}

export const SubmitResult: React.FC<ISubmitResult> = ({
  displayType = 'thankYou',
  embedded = false,
  onCloseModal,
}: ISubmitResult) => {
  const content: SubmitResultContent = {
    title: titleMap[displayType],
    description: descriptionMap[displayType],
    iconPath: iconPathMap[displayType],
  }

  return (
    <Container data-testid={`agent-review-${displayType}`}>
      <ContentWrapper>
        <ImageIcon
          src={`/review/${content.iconPath}`}
          {...(displayType === 'expiredSession' && { marginBottom: '20px' })}
        />
        <Title>{content.title}</Title>
        <h1>{content.description && content.description}</h1>
        {embedded && (
          <CloseButton
            id="close-modal-button"
            data-testid="close-modal-button"
            large
            google
            text="Close"
            mWidth="100%"
            height="40px"
            width="285px"
            margin="0 auto 15px auto"
            size="16px"
            onClick={onCloseModal}
          />
        )}
      </ContentWrapper>
    </Container>
  )
}
