import styled from 'styled-components'

export const ButtonBadge = styled.div<IButtonBadge>`
  ${props => `padding: ${props.padding ? `${props.padding}` : '2px 11px'}`};
  ${props => props.margin && `margin: ${props.margin}`};
  border-radius: 12px;
  ${props => `font-size: ${props.size ? props.size : '14px'}`};
  font-family: Source Sans Pro, sans-serif;
  color: white;
  ${props => props.bgColor && `background-color: ${props.bgColor}`};
  position: absolute;
  font-weight: 600;
  ${props => `top: ${props.top ? `${props.top}` : '-13px'}`};
  right: -12px;
`
export const ButtonBadgeContainer = styled.div<IButtonBadgeContainer>`
  position: relative;
  display: inline-block;
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.padding && `padding: ${props.padding}`};
`
