import type { ITextAreaElement } from './types'

import styled from 'styled-components'

import { baseInputCSS } from './base-input-css'

export const TextArea = styled.textarea<ITextAreaElement>`
  resize: ${({ $noResize }) => ($noResize ? 'none' : 'vertical')};
  ${baseInputCSS}
`
