import type { IEmailModalFormValues } from './types'
import type { ReactElement } from 'react'
import type { ISendEmailToAgentRequestPayload } from 'store/public-messaging/types'
import type { IUserProfile } from 'store/users/types'

import { buildPath } from '@rentspree/path'
import { useEffect, useState } from 'react'

import { BaseModal, Body, ButtonLink, H3, RentSpreeSweetalertGlobalStyle } from 'components'
import env from 'env'
import { useDispatch, useSelector } from 'store'
import { selectAgentProfile } from 'store/agent-profile'
import { actions, selectPublicMessagingApiStatus } from 'store/public-messaging'
import { actions as toastActions } from 'store/toast'
import { selectUserProfile, selectUserProfileUserType } from 'store/users/selectors'
import tracker, { events } from 'tracker'

import { openSweetAlertForLoggedIn, openSweetAlertForAnonymousUser } from './constants'
import { ContactAgentEmailForm } from './form'
import { ContactAgentEmailModalContent, ContactAgentInformationAlert } from './style'

interface IProps {
  agentFullName: string
  source?: 'agent-profile' | 'listing-syndication'
  children?: (onClick?: () => void, hideMobile?: boolean) => ReactElement
  onModalOpen?: () => void
  onModalClose?: () => void
  modalContentStyle?: IElement
  defaultMessage: string
  property?: {
    address: string
    id: string
  }
  profile?: IUserProfile
}

const SweetAlertGlobalStyle = RentSpreeSweetalertGlobalStyle as unknown as React.FC

export const ContactAgentEmailModal: React.FC<IProps> = props => {
  const {
    agentFullName,
    children = () => undefined,
    onModalOpen = () => {
      /* empty function */
    },
    onModalClose = () => {
      /* empty function */
    },
    modalContentStyle = {},
    defaultMessage,
    property = undefined,
    profile = {},
  } = props
  const dispatch = useDispatch()
  const [isShowModal, setIsShowModal] = useState(false)

  const { userId, email: agentEmail } = useSelector(selectAgentProfile)
  const sendEmailApiStatus = useSelector(selectPublicMessagingApiStatus)
  const [emailApplicant, setEmailApplicant] = useState<string>('')
  const buttonLocation = 'AP public page'
  const { id: renterId } = useSelector(selectUserProfile) || {}
  const userType = useSelector(selectUserProfileUserType)
  const eventUserState = !renterId ? 'anonymous' : 'loggedin'

  const openSweetAlert = (): void => {
    if (renterId) openSweetAlertForLoggedIn(buttonLocation, userType)
    else openSweetAlertForAnonymousUser(buttonLocation, emailApplicant)
  }

  useEffect(() => {
    switch (sendEmailApiStatus) {
      case 'fulfilled':
        setIsShowModal(false)
        openSweetAlert()
        break
      case 'rejected':
        setIsShowModal(false)
        dispatch(
          toastActions.addToast({
            status: 'error',
            titleMessage: 'Something went wrong while sending message. Please try again.',
            bodyMessage: '',
          }),
        )
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEmailApiStatus])

  useEffect(() => {
    if (isShowModal && onModalOpen) {
      onModalOpen()
    } else if (onModalClose) {
      onModalClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowModal])

  const handleClickCtaButton = () => {
    tracker?.trackEvent(events.PUBLIC_PAGE.CONTACT_TO_AGENT, { state: eventUserState })
    setIsShowModal(true)
  }

  const handleSendEmail = ({ fullName, email, phone, message }: IEmailModalFormValues) => {
    const textMessage = message || defaultMessage

    tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_SEND_MESSAGE, {
      state: eventUserState,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      button_location: buttonLocation,
    })
    const payload: ISendEmailToAgentRequestPayload = {
      sender: {
        fullName,
        email,
        phone: phone || undefined,
      },
      recipient: {
        _id: userId || '',
        email: agentEmail || '',
      },
      message: textMessage,
      property,
    }
    // eslint-disable-next-line no-void
    void dispatch(actions.sendEmailToAgent(payload))
    setEmailApplicant(email)
  }

  const redirectToLogin = () => {
    // TO-DO: fix 'buildPath' in @rentspree/path to return only string
    const path =
      buildPath(`${env.BASE_URL}/account/login`, {}, { continue: window.location.href }) ?? ''
    window.location.assign(path)
  }

  return (
    <>
      <SweetAlertGlobalStyle />
      {children?.(handleClickCtaButton, isShowModal)}
      <BaseModal
        show={isShowModal}
        onClose={() => setIsShowModal(false)}
        $width="542px"
        noContentPadding
      >
        <ContactAgentEmailModalContent
          $padding="40px 56px"
          $mPadding="24px 28px"
          {...modalContentStyle}
        >
          <H3 $mTextStyle="h2" $mb="16" $mMargin="0 0 16px">
            Contact {agentFullName}
          </H3>
          {!renterId && (
            <ContactAgentInformationAlert variant="info" noClose $mMargin="0 0 24px">
              <Body $textStyle="body-small" $mTextStyle="body" $block>
                Already have an account?{' '}
                <ButtonLink
                  text="Login"
                  $textStyle="body-small"
                  $mTextStyle="body"
                  $bold
                  $underline
                  onClick={redirectToLogin}
                />{' '}
                to chat via direct messages.
              </Body>
            </ContactAgentInformationAlert>
          )}
          <ContactAgentEmailForm
            handleSubmit={handleSendEmail}
            isLoading={sendEmailApiStatus === 'pending'}
            defaultMessage={defaultMessage}
            profile={profile}
          />
        </ContactAgentEmailModalContent>
      </BaseModal>
    </>
  )
}
