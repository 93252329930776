import styled from 'styled-components'

import { Toast } from 'components/common/atoms/toast'

interface IToast {
  id?: string
  width?: number
  status: string
  titleMessage: string
  bodyMessage: string
}

interface IToasts {
  toasts: Array<IToast>
  timeOut: number
  width: number
  onClose: (id?: string) => void
}

const Floater = styled.div<{ $width?: number }>`
  position: fixed;
  z-index: 10001;
  display: flex;
  width: ${({ $width }) => $width || '350px'};
  flex-direction: column;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 991px) {
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    left: 0;
    transform: translateX(0);
  }
`

export const ToastFloater: React.FC<IToasts> = (props: IToasts) => {
  const { toasts, onClose, width, timeOut } = props
  return (
    <Floater $width={width || toasts[0]?.width}>
      {toasts?.map(toast => (
        <Toast
          key={toast.id}
          onClose={() => onClose(toast.id)}
          status={toast.status}
          titleMessage={toast.titleMessage}
          bodyMessage={toast.bodyMessage}
          timeOut={timeOut}
        />
      ))}
    </Floater>
  )
}
