export const parseArguments = (orderArgs: unknown[], defaultArgs: unknown[]): string => {
  const orders: (string | undefined)[] = orderArgs.map(val => {
    const order = val !== undefined ? String(val) : undefined
    return order
  })
  const defaultValue: string[] = defaultArgs.map(val => String(val))

  orders.reverse()
  defaultValue.reverse()

  const args: string[] = orders.reduce(
    (acc: string[], val: string | undefined, idx: number): string[] => {
      if ((val === undefined || val === defaultValue[idx]) && acc.length === 0) {
        return acc
      }

      if (val === undefined || val === defaultValue[idx]) {
        acc.push('')
        return acc
      }
      acc.push(val)
      return acc
    },
    [],
  )
  return args.reverse().join(':')
}
