import styled from 'styled-components'

import { COLOR } from 'styles/constants'

type FooterProp = IElement

const FooterWrapper = styled.div`
  background-color: ${COLOR.BLUE_LV1};
  position: relative;
  overflow: hidden;
  padding: 43px 108px;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    top: -38px;
    left: 0;
    width: 112px;
    height: 321px;
    background-image: url(../profile/footer-rectangle.svg);
  }

  &::after {
    bottom: 0;
    right: 0;
    width: 118px;
    height: 334px;
    background-image: url(../profile/footer-rectangle-group.svg);
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
  }

  @media (max-width: 991px) {
    padding: 28px 32px 180px 32px;

    &::before,
    &::after {
      display: none;
    }
  }
`
const ContentWrapper = styled.div`
  width: 1064px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 466px;
    height: 204px;
    background-image: url(../profile/footer-home.png);
  }

  & > div {
    position: relative;
    z-index: 2;
  }

  @media (min-width: 991px) {
    li {
      font-size: 12px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    &::before {
      bottom: 560px;
      left: 65px;
    }

    & > div {
      width: 50%;

      &:first-child {
        min-height: 216px;
      }

      &:nth-last-child(2) {
        margin-bottom: 64px;
        width: 100%;
      }

      &:last-child {
        width: 100%;
      }
    }

    li {
      font-size: 12px;
      margin-bottom: 16px;
    }
  }
`

export const FooterTemplate: React.FC<React.PropsWithChildren<FooterProp>> = ({ children }) => (
  <FooterWrapper>
    <ContentWrapper>{children}</ContentWrapper>
  </FooterWrapper>
)
