import type { IProcessingOptionsInterface } from '../interfaces'
import type { ResizeOptionsType } from '../types'

import { parseArguments } from '../helper/helper'
import { ResizingType } from '../types'

export class ResizeProcessingOption implements IProcessingOptionsInterface {
  width?: number

  height?: number

  enlarge?: boolean

  extend?: boolean

  rsType?: ResizingType

  readonly orderOptions = ['rsType', 'width', 'height', 'enlarge', 'extend']

  readonly defaultValue = {
    rsType: ResizingType.FIT,
    width: undefined,
    height: undefined,
    enlarge: false,
    extend: false,
  }

  constructor({ rsType, width, height, enlarge, extend }: ResizeOptionsType) {
    this.width = width
    this.height = height
    this.enlarge = enlarge
    this.extend = extend
    this.rsType = rsType
  }

  update({ rsType, width, height, enlarge, extend }: ResizeOptionsType): this {
    this.width = width
    this.height = height
    this.enlarge = enlarge
    this.extend = extend
    this.rsType = rsType
    return this
  }

  parseOptions(): string {
    const argsOrder: unknown[] = this.orderOptions.map((key: string): unknown => this[key])
    const defaultValue: unknown[] = this.orderOptions.map(
      (key: string): unknown => this.defaultValue[key],
    )

    const args = parseArguments(argsOrder, defaultValue)

    return `rs:${args}`
  }
}
