import type { ICheckbox } from './type'

import styled from 'styled-components'

import { COLOR } from 'styles/constants'
import { elementStyle } from 'styles/element-style'

// cannot figured out how to set width here or where?
export const Label = styled.label<IElement>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  cursor: pointer;
  ${elementStyle}
`

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-top: 1%; // this for align the box and text some how
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2.4px;
  transform: translateY(-40%);
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<Partial<ICheckbox>>`
  position: relative;
  width: 16px;
  height: 16px;
  background: ${props => (props.isChecked ? COLOR.BLUE_LV2 : 'transparent')};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid ${props => (props.isError ? COLOR.RED_LV2 : COLOR.BLUE_LV2)};

  ${props => props.isError && props.isChecked && `background: ${COLOR.RED_LV2}`};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 4px ${props => (props.isError ? COLOR.RED_LV4 : COLOR.BLUE_LV6)};
  }

  ${HiddenCheckbox}:disabled + & {
    border-color: ${COLOR.GREY_LV3};
    background-color: ${COLOR.GREY_LV3};
    cursor: not-allowed;
  }

  ${Icon} {
    visibility: ${props => (props.isChecked ? 'visible' : 'hidden')};
  }
`

export const CheckboxBackground = styled.div<Partial<ICheckbox>>`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${HiddenCheckbox}:focus + ${StyledCheckbox} > & {
    background: ${props => (props.isError ? COLOR.RED_LV4 : COLOR.BLUE_LV6)};
  }
`
