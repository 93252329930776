import classNames from 'classnames'
import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { useTracker } from '@hooks'
import { Img } from 'components/common/atoms/img'
import env from 'env'
import { NAVBAR } from 'tracker/events'

const RENTSPREE_LOGO = 'https://cdn.rentspree.com/static-files/logo/rentspree-logo-primary.png'

interface INavbarV2 extends IFlexItem, IElement {}

const Navbar = styled.div<IFlexItem & IElement>`
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: ${({ $justifyContent }) => $justifyContent || 'center'};
  background: #ffffff;
  box-shadow: 0px 2px 10px -1px rgba(0, 37, 112, 0.05);
  position: sticky;
  top: 0;
  z-index: 6;
  transition: all 0.3s ease-in-out;
  margin: ${({ $margin }) => $margin || 'auto'};
  padding: ${({ $padding }) => $padding || 'auto'};
  img {
    cursor: pointer;
  }

  @media (max-width: 991px) {
    height: 57px;
    img {
      height: 30px;
    }

    &.hide {
      top: -57px;
    }
  }
`

export const NavbarV2: React.FC<INavbarV2> = ({ $justifyContent, $margin, $padding }) => {
  const track = useTracker()
  const withLeavePageViaNavbarTracker =
    (func: (e: React.MouseEvent) => void) => (e: React.MouseEvent) => {
      track(NAVBAR.LEAVE_PAGE_VIA_NAVBAR)
      func(e)
    }
  const [navState, setNavState] = useState<{ showNav: boolean; scrollPosition: number }>({
    showNav: true,
    scrollPosition: 0,
  })

  const handleScroll = () => {
    const position = document.body.getBoundingClientRect().top

    setNavState(prev => {
      const oldPosition = prev.scrollPosition
      return {
        ...prev,
        scrollPosition: position,
        showNav: position > oldPosition,
      }
    })
  }

  const openLink = (url: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    window.open(url, '_self')
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Navbar
      id="navbar-v2"
      $justifyContent={$justifyContent}
      $margin={$margin}
      $padding={$padding}
      className={classNames(navState.showNav ? 'show' : 'hide')}
    >
      <Img
        src={RENTSPREE_LOGO}
        alt="navbar-rentspree-logo"
        height="32px"
        onClick={withLeavePageViaNavbarTracker(openLink(env.BASE_URL))}
      />
    </Navbar>
  )
}
