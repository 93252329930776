import { SimpleTooltip } from '@rentspree/ssr-component-v2'
import isEmpty from 'lodash/isEmpty'

import { H2, Img, Span } from 'components'
import { useSelector } from 'store'
import {
  selectAgentProfileFullName,
  selectAgentVerifiedStatus,
} from 'store/agent-profile/selectors'

export const AboutMeTitleText: React.FC = () => {
  const fullName = useSelector(selectAgentProfileFullName)
  const isAgentVerified = useSelector(selectAgentVerifiedStatus)

  if (!isAgentVerified) {
    return <H2>{isEmpty(fullName) ? 'Name' : fullName}</H2>
  }

  const badgeIcon = (
    <span className="d-flex align-items-center">
      <Img src="/profile/verified-badge.svg" alt="Verified" $ml="15" />
    </span>
  )

  return (
    <span className="d-flex align-items-center">
      <H2>{fullName}</H2>
      <SimpleTooltip
        bubbleWidth="unset"
        position="TOP"
        background="black"
        color="white"
        componentTitle={badgeIcon}
      >
        <Span $size="12px" color="white" $weight="400">
          Account verified
        </Span>
      </SimpleTooltip>
    </span>
  )
}
