import { Hr as HrV2 } from '@rentspree/ssr-component-v2'
import styled from 'styled-components'

import { COLOR } from 'styles/constants'
import { elementStyle } from 'styles/element-style'

type HrProps = IElement

const StyledHr = styled(HrV2)<HrProps>`
  color: ${COLOR.GREY_LV3};
  ${elementStyle};
`

export const Hr: React.FC<HrProps> = props => <StyledHr {...props} />
export default Hr
