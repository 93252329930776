import styled from 'styled-components'

import { textStyle } from 'styles/text-style'

const StyledH2 = styled.h2`
  ${textStyle}
`

export const H2: React.FC<React.PropsWithChildren<ISpan>> = ({ children, ...props }) => (
  <StyledH2 $textStyle="h2" {...props}>
    {children}
  </StyledH2>
)
