import type { InputWithLabelElementInterface } from './types'
import type { SelectInputOption } from 'components/common/atoms/input'

import { Div } from 'components/common/atoms/div'
import { Img } from 'components/common/atoms/img'
import { SelectInput } from 'components/common/atoms/input'
import { Body, Span } from 'components/common/atoms/typography'
import { COLOR } from 'styles/constants'

export type SelectInputWithLabelProps = InputWithLabelElementInterface<HTMLSelectElement> & {
  name: string
  options: SelectInputOption[]
  onChange?: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
}

export const SelectInputWithLabel: React.FC<SelectInputWithLabelProps> = ({
  id,
  label,
  name,
  value,
  options,
  error,
  disabled,
  isRequired,
  showErrorIcon,
  onChange,
  onBlur,
  placeholder,
  onFocus,
  ...props
}) => (
  <Div id={`${id}Group`} {...props}>
    <Span as="label" id={`${id}Label`} htmlFor={id} $textStyle="body" $block>
      {label}
      {isRequired && <Body $color={COLOR.RED_LV2}> *</Body>}
    </Span>
    <SelectInput
      id={id}
      name={name}
      defaultValue={value}
      onChange={newValue => onChange && onChange(newValue)}
      onBlur={onBlur}
      placeholder={placeholder}
      options={options}
      disabled={disabled}
      $error={!!error}
      $margin="8px 0 0"
      $display="block"
    />
    {error && typeof error === 'string' && (
      <Div className="d-flex align-items-center" $mt="8">
        {showErrorIcon && (
          <Img
            src="../profile/icon/circle-exclaimation-outline.svg"
            alt="text-input-error-icon"
            $width="16px"
            $height="16px"
            $mr="8"
          />
        )}
        <Body $textStyle="caption" $color={COLOR.RED_LV2}>
          {error}
        </Body>
      </Div>
    )}
  </Div>
)
