import type { ContainerProps as BSContainerProps } from 'react-bootstrap'

import { Container as BSContainer } from 'react-bootstrap'
import styled from 'styled-components'

export interface IPageContainerProps extends BSContainerProps {
  isWide?: boolean
}

const StyledContainer = styled(BSContainer)`
  width: ${({ $wide }) => ($wide ? '920px' : '500px')};
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 35px;
  padding-left: 0px;
  padding-right: 0px;
  /* mobile & tablet size */
  @media (max-width: 991px) {
    width: unset;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 20px;
    margin-right: 20px;
  }
`

export const PageContainer: React.FC<IPageContainerProps> = (props: IPageContainerProps) => {
  const { isWide, children, ...otherProps } = props
  return (
    <StyledContainer {...otherProps} $wide={isWide} fluid>
      {children}
    </StyledContainer>
  )
}

PageContainer.defaultProps = {
  isWide: false,
}

export default PageContainer
