import styled from 'styled-components'

import { COLOR, RSP_FONT } from 'styles/constants'

interface ListWrapperInterface {
  $height: number | string
  $overflowY: string
}

interface BlurInterface {
  $height: number | string
}
export const ListWrapper = styled.div<ListWrapperInterface>`
  ${({ $height }) => $height && `height: ${$height}px;`}
  ${({ $overflowY }) => $overflowY && `overflow-y: ${$overflowY};`}
position: relative;
  transition: height 0.25s ease-in;
`

export const ShowMoreButton = styled.button`
  border: none;
  background-color: inherit;
  font-size: ${RSP_FONT.bodyNormalSize};
  line-height: 24px;
  font-weight: ${RSP_FONT.normalWeight};
  color: ${COLOR.BLUE_LV5};
  cursor: pointer;
  display: inline-block;
`

export const Blur = styled.div<BlurInterface>`
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 100%
  );
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ $height }) => `${$height}px` || '100px'};
`
