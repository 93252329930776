import { ButtonLink, RichText } from 'components'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constant'

import { TERMS_AND_PRIVACY } from './constants'

export const TermBox: React.FC = () => (
  <RichText $textStyle="caption" $block>
    {TERMS_AND_PRIVACY}
    <ButtonLink
      $textStyle="caption"
      $regular
      text="Terms of Use"
      onClick={() => window.open(TERMS_OF_USE_URL, '_blank')}
    />{' '}
    and{' '}
    <ButtonLink
      $textStyle="caption"
      $regular
      text="Privacy Policy"
      onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
    />
    .
  </RichText>
)
