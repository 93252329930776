export const COLOR = {
  BLUE_LV1: '#002570',
  BLUE_LV2: '#004BB0',
  BLUE_LV3: '#195DB8',
  BLUE_LV4: '#007ADF',
  BLUE_LV5: '#3B99FC',
  BLUE_LV6: '#E2F0FF',
  BLUE_10: '#f3f9ff',
  BLUE_20: '#C4E0FE',

  SWALLOW_BLUE_LV1: '#00B4CD',

  GREY_LV1: '#333333',
  GREY_LV2: '#5F5F5F',
  GREY_LV3: '#AAAAAA',
  GREY_LV4: '#E5E5E5',
  GREY_LV5: '#F1F2F2',
  GREY_LV6: '#F9F9F9',
  GREY_UNDEFINED: '#999999',

  GRAY_SCALE_15: '#EBEBEB',
  GRAY_SCALE_20: '#D6D6D6',
  GRAY_SCALE_30: '#999999',
  GRAY_SCALE_40: '#858585',

  RED_LV2: '#D0403A',
  RED_LV4: '#FBDFDC',

  GREEN_LV3: '#1FC06D',
  MINT_GREEN_LV3: '#71FFE2',

  SVG_BLUE_LV4:
    'invert(31%) sepia(35%) saturate(4015%) hue-rotate(190deg) brightness(98%) contrast(101%)',
  SVG_GREY_LV3:
    'invert(89%) sepia(0%) saturate(1438%) hue-rotate(193deg) brightness(79%) contrast(84%)',

  GRADIENT_BLUE_LV1: 'linear-gradient(90deg, #004BB0 7.19%, #007ADF 107%);',

  BRIGHT_ROYAL_BLUE: '#4b97f5',
  DEPP_BLUE_BTN: 'rgb(61,138,247)',
  DEEP_BLUE_DARK: '#0062B3',
  DARK_DEEP_BLUE: 'rgb(29, 102, 206)',
  DARK_FACEBOOK_BLUE: '#2a477c',
  DARK_STEEL_BLUE: '#303E51',
  FACEBOOK_BLUE: '#4565a0',
  LIGHT_DEEP_BLUE: 'rgba(59, 153, 252, 0.1)',
  SKY_BLUE: '#7cd5ff',
  STEEL_BLUE: '#688098',
  RENTSPREE_DARK_BLUE: '#2563AD',
  LIGHT_STEEL_BLUE: '#546A84',
  VERY_LIGHT_STEEL_BLUE: '#c3cbdf',
  WATER_BLUE: '#146dca',

  SECONDARY: '#405369',
  LIGHT_SECONDARY: '#546A84',

  BORDER_GREY: 'rgba(0, 0, 0, 0.23)',
  DARK_GREY: '#4a4a4a',
  HOVER_GREY: 'rgba(0, 0, 0, 0.1)',
  SHADOW_GREY: 'rgba(0, 0, 0, 0.08)',

  BLACK: '#000000',
  WHITE: '#ffffff',

  RED_PALE: '#FFE9E9',
  DARK_NEGATIVE_RED: '#a72f31',
  LIGHT_RED: '#FF7D7D',
  LIGHT_SOFT_RED: 'rgba(241, 85, 85, 0.1)',
  NEGATIVE_RED: '#F15555',
  SOFT_RED: '#f15555',

  GREEN: '#80c640',
  GREEN_PALE: '#E6FFCF',
  DARK_GREEN: '#67A033',
  SPRING_GREEN: '#b6f67b',

  YELLOW: '#d3b84c',
  YELLOW_PALE: '#FEF5D9',
  DARK_YELLOW: '#a7903f',
  DARK_YELLOW_BRIGHTER: '#AC932D',
  LIGHT_YELLOW: '#f2d76b',
  SILKY_YELLOW: '#e9d230',

  ORANGE: '#ff8a23',
  DARK_DEEP_ORANGE: '#E07414',

  PALE: '#fef5d9',

  PRIMARY: 'linear-gradient(90deg, #2563AD -0.73%, #007ADF 100.02%)',
  PRIMARY_TOGGLE: 'linear-gradient(90deg, #0062B3 0%, #0099AE 100%)',
}
