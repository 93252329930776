import type { InputWithLabelElementInterface } from './types'
import type { NumberFormatOnValueChange } from 'components/common/atoms/input/types'
import type { NumberFormatProps } from 'react-number-format'

import { Body, Div, Img, NumberFormatInput, Span } from 'components/common/atoms'
import { COLOR } from 'styles/constants'

interface INumberFormatInputWithLabelProps
  extends Omit<InputWithLabelElementInterface<HTMLInputElement>, 'onChange'> {
  onChange?: NumberFormatOnValueChange
  format?: string
}

function getInputPropsByType(type?: string): NumberFormatProps {
  switch (type) {
    case 'phone':
      return {
        format: '(###) ###-####',
        type: 'tel',
      }
    case 'date':
      return {
        format: '##/##/####',
        mask: ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y'],
        type: 'text',
      }
    case 'number':
      return {
        thousandSeparator: true,
        decimalScale: 0,
        allowNegative: false,
        type: 'text',
      }
    default:
      return {
        type: 'tel',
      }
  }
}

export const NumberFormatInputWithLabel: React.FC<INumberFormatInputWithLabelProps> = ({
  id,
  label,
  type,
  name,
  value,
  error,
  disabled,
  isRequired,
  showErrorIcon,
  inputMode,
  onChange,
  onBlur,
  placeholder,
  ...props
}) => (
  <Div {...props}>
    <Span as="label" id={`${id}Label`} htmlFor={id} $textStyle="body" $block>
      {label}
      {isRequired && <Body $color={COLOR.RED_LV2}> *</Body>}
    </Span>
    <NumberFormatInput
      id={id}
      {...getInputPropsByType(type)}
      name={name}
      value={value}
      onValueChange={onChange}
      onBlur={onBlur}
      inputMode={inputMode}
      placeholder={placeholder}
      disabled={disabled}
      $error={!!error}
      $width="100%"
      $margin="8px 0 0"
      $display="block"
    />
    {error && typeof error === 'string' && (
      <Div className="d-flex align-items-center" $mt="8">
        {showErrorIcon && (
          <Img
            src="../profile/icon/circle-exclaimation-outline.svg"
            alt="number-format-input-error-icon"
            $width="16px"
            $height="16px"
            $mr="8"
          />
        )}
        <Body $textStyle="caption" $color={COLOR.RED_LV2}>
          {error}
        </Body>
      </Div>
    )}
  </Div>
)
