import styled from 'styled-components'

import { COLOR } from 'styles/constants'
import { textStyle } from 'styles/text-style'

interface IA extends ISpan {
  href: string
  className?: string
}

const StyledA: React.FC<ISpan> = styled.a`
  ${textStyle}
  ${(props: ISpan) => props.$pointer && 'cursor: pointer;'}
  ${(props: ISpan) => `color: ${props.$color ? props.$color : COLOR.BLUE_LV5};`}
`

export const A: React.FC<React.PropsWithChildren<IA>> = ({ children, ...style }) => (
  <StyledA {...style}>{children}</StyledA>
)

A.defaultProps = {
  className: '',
}
