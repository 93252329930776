import styled from 'styled-components'

import { textStyle } from 'styles/text-style'

interface ITextWrapper {
  $hideText: boolean
  $height: string
  $lineHeight: string
  $size?: string
}

export const TextWrapper = styled.div<ITextWrapper>`
  position: relative;
  font-size: ${({ $size }) => $size || '16px'};
  line-height: ${({ $lineHeight }) => $lineHeight};
  height: ${({ $hideText, $height }) => ($hideText ? $height : 'auto')};
  overflow: ${({ $hideText }) => ($hideText ? 'hidden' : 'auto')};
  margin-bottom: 10px;

  @media (max-width: 991px) {
    margin-bottom: 0;
  }
`

export const TextOverlay = styled.div<{ $lineOffset?: string }>`
  position: absolute;
  width: 100%;
  ${({ $lineOffset }) => `height: calc(100% - ${$lineOffset || 0})`};
  bottom: 0;
  left: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0) -20%, rgba(255, 255, 255, 0.9) 56.19%);
`

export const ShowMoreTextWrapper = styled.div`
  ${textStyle}
`
