import styled from 'styled-components'

export const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 21px;

  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
`
