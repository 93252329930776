import { css } from 'styled-components'

import { COLOR } from 'styles/constants'

export const transparentBtn = css`
  background-color: transparent;
  border: 0px;
  color: ${COLOR.WHITE};
`

export const blueBtn = css`
  background-color: ${COLOR.DEPP_BLUE_BTN};
  border: 1px solid ${COLOR.DEPP_BLUE_BTN};
  color: white;
  &:hover,
  &:focus {
    background-color: ${COLOR.DARK_DEEP_BLUE};
    border: 1px solid ${COLOR.DARK_DEEP_BLUE};
    color: white;
  }
  &:disabled {
    transition: none;
    background-color: ${COLOR.GREY_LV1};
    border: 1px solid ${COLOR.GREY_LV1};
  }
`

export const disabledBlackTextBtn = css`
  color: ${COLOR.GREY_LV4};
  &:hover {
    color: ${COLOR.GREY_LV4};
  }
`

export const blackTextBtn = css<IStyledButton>`
  background-color: transparent;
  border: 0px;
  &:hover {
    color: ${COLOR.GREY_LV3};
  }
  ${props => props.disabled && disabledBlackTextBtn};
`

export const greenGradientBtn = css`
  background: linear-gradient(to right, ${COLOR.GREEN}, ${COLOR.SPRING_GREEN});
  border: 0px;
  color: ${COLOR.WHITE};
`

export const blueGradient = css`
  background: linear-gradient(to right, ${COLOR.BLUE_LV2}, ${COLOR.BLUE_LV2}, ${COLOR.BLUE_LV4});
`

export const yellowGradient = css`
  background: linear-gradient(to right, ${COLOR.YELLOW}, ${COLOR.YELLOW}, ${COLOR.LIGHT_YELLOW});
`

export const redGradient = css`
  background: linear-gradient(
    to right,
    ${COLOR.NEGATIVE_RED},
    ${COLOR.NEGATIVE_RED},
    ${COLOR.LIGHT_RED}
  );
`

export const secondaryGradient = css`
  background: linear-gradient(
    to right,
    ${COLOR.SECONDARY},
    ${COLOR.SECONDARY},
    ${COLOR.LIGHT_SECONDARY}
  );
`

export const blueGradientHovered = css`
  background: linear-gradient(to right, ${COLOR.BLUE_LV2}, ${COLOR.BLUE_LV2});
  border: 0px;
  color: ${COLOR.WHITE};
`

export const yellowGradientHovered = css`
  background: linear-gradient(to right, ${COLOR.YELLOW}, ${COLOR.YELLOW});
  border: 0px;
  color: ${COLOR.WHITE};
`

export const redGradientHovered = css`
  background: linear-gradient(to right, ${COLOR.NEGATIVE_RED}, ${COLOR.NEGATIVE_RED});
  border: 0px;
  color: ${COLOR.WHITE};
`

export const secondaryGradientHovered = css`
  background: linear-gradient(to right, ${COLOR.SECONDARY}, ${COLOR.SECONDARY});
  border: 0px;
  color: ${COLOR.WHITE};
`

export const facebookBtn = css`
  color: ${COLOR.WHITE};
  background-color: ${COLOR.FACEBOOK_BLUE};
  font-weight: 600;
  &:hover {
    background-color: ${COLOR.DARK_FACEBOOK_BLUE};
  }
  transition: all, 0.5s;
  transform: background-color;
`

export const googleBtn = css`
  border: solid 1px ${COLOR.GREY_LV3};
  font-weight: 600;
  &:hover,
  &:focus {
    background-color: ${COLOR.GREY_LV6};
  }
  background-color: ${COLOR.WHITE};
  transition: all, 0.5s;
  transform: background-color;
  &:disabled {
    transition: none;
    cursor: not-allowed;
    color: ${COLOR.GREY_LV3};
    background: ${COLOR.WHITE};
    border: 1px solid ${COLOR.GREY_LV4};
  }
`

export const blueText = css`
  display: inline-flex;
  padding: 0;
  height: auto;
  color: ${COLOR.BLUE_LV2};
  &:hover {
    color: ${COLOR.WATER_BLUE};
  }
`
export const redBtn = css`
  background-color: ${COLOR.NEGATIVE_RED};
  border: 1px solid ${COLOR.NEGATIVE_RED};
  color: ${COLOR.WHITE};
  &:hover,
  &:focus {
    color: ${COLOR.WHITE};
    background-color: ${COLOR.DARK_NEGATIVE_RED};
    border: 1px solid ${COLOR.DARK_NEGATIVE_RED};
  }
`
